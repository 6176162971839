export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: GraphQL_Date;
  /** Notification emails scalar */
  NotificationEmailsScalar: GraphQL_NotificationEmailsScalar;
};

export enum AccessTokenError {
  EmailDoesNotExist = 'EmailDoesNotExist',
  EmailNotSent = 'EmailNotSent',
  EmailOrPasswordNotSent = 'EmailOrPasswordNotSent',
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  ErrorOnOrganizationCreation = 'ErrorOnOrganizationCreation',
  GoogleTokenInvalid = 'GoogleTokenInvalid',
  InvalidCharityNumber = 'InvalidCharityNumber',
  InvalidOrganizationName = 'InvalidOrganizationName',
  NoDonorAllowedOnMobileApp = 'NoDonorAllowedOnMobileApp',
  PasswordDoNotMatch = 'PasswordDoNotMatch',
  ResetTokenDoNotMatch = 'ResetTokenDoNotMatch',
  StytchOrganizationIdNotFound = 'StytchOrganizationIdNotFound',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  TwoFactorCodeDoesNotMatch = 'TwoFactorCodeDoesNotMatch',
  TwoFactorCodeExpired = 'TwoFactorCodeExpired',
  UnauthorizedToken = 'UnauthorizedToken',
  UrlInvalid = 'UrlInvalid',
  UserAlreadyExistsWithOrganization = 'UserAlreadyExistsWithOrganization',
  UserNotFoundWithOrganization = 'UserNotFoundWithOrganization'
}

export type AccessTokenObject = {
  __typename?: 'AccessTokenObject';
  accessToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<AccessTokenError>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  shouldAuthenticateBySms?: Maybe<Scalars['Boolean']>;
  shouldAuthenticateWithTwoFactor?: Maybe<Scalars['Boolean']>;
  shouldRedirectToMFAConfig?: Maybe<Scalars['Boolean']>;
  stytchVerdict?: Maybe<Scalars['String']>;
};

export type AccessTokenObject5eXnj20X = {
  __typename?: 'AccessTokenObject5eXnj20X';
  error?: Maybe<AccessTokenObject5eXnj20XErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject5eXnj20XErrorObject = {
  __typename?: 'AccessTokenObject5eXnj20XErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObject9H4e4x5K = {
  __typename?: 'AccessTokenObject9H4e4x5K';
  error?: Maybe<AccessTokenObject9H4e4x5KErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject9H4e4x5KErrorObject = {
  __typename?: 'AccessTokenObject9H4e4x5KErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectD67Mi8Fe = {
  __typename?: 'AccessTokenObjectD67MI8Fe';
  error?: Maybe<AccessTokenObjectD67Mi8FeErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectD67Mi8FeErrorObject = {
  __typename?: 'AccessTokenObjectD67MI8FeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectSAljh70I = {
  __typename?: 'AccessTokenObjectSAljh70I';
  error?: Maybe<AccessTokenObjectSAljh70IErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectSAljh70IErrorObject = {
  __typename?: 'AccessTokenObjectSAljh70IErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectcIeIIjFq = {
  __typename?: 'AccessTokenObjectcIeIIjFQ';
  error?: Maybe<AccessTokenObjectcIeIIjFqErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectcIeIIjFqErrorObject = {
  __typename?: 'AccessTokenObjectcIeIIjFQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectjZmTiq3Z = {
  __typename?: 'AccessTokenObjectjZmTIQ3Z';
  error?: Maybe<AccessTokenObjectjZmTiq3ZErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectjZmTiq3ZErrorObject = {
  __typename?: 'AccessTokenObjectjZmTIQ3ZErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjects9jO4yds = {
  __typename?: 'AccessTokenObjects9jO4yds';
  error?: Maybe<AccessTokenObjects9jO4ydsErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjects9jO4ydsErrorObject = {
  __typename?: 'AccessTokenObjects9jO4ydsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenResponseObject = {
  __typename?: 'AccessTokenResponseObject';
  error?: Maybe<AccessTokenResponseObjectErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenResponseObjectErrorObject = {
  __typename?: 'AccessTokenResponseObjectErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccountBalancesObject = {
  __typename?: 'AccountBalancesObject';
  available?: Maybe<Array<BalanceObject>>;
  issuing?: Maybe<AccountBalancesObject>;
  pending?: Maybe<Array<BalanceObject>>;
};

export type AccountBalancesObject2dhjTz1U = {
  __typename?: 'AccountBalancesObject2dhjTz1U';
  error?: Maybe<AccountBalancesObject2dhjTz1UErrorObject>;
  object?: Maybe<AccountBalancesObject>;
};

export type AccountBalancesObject2dhjTz1UErrorObject = {
  __typename?: 'AccountBalancesObject2dhjTz1UErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ActivateDonationFormInput = {
  donationFormId: Scalars['String'];
  isOnboarding: Scalars['Boolean'];
};

export type ActivateTicketingInput = {
  isOnboarding: Scalars['Boolean'];
  ticketingId: Scalars['String'];
};

export type AggregateRateFieldsObject = {
  __typename?: 'AggregateRateFieldsObject';
  commandId: Scalars['String'];
  rateFields: Array<RateFieldObjectWithCount>;
};

export type AggregateRateFieldsObject8v6iiYm9 = {
  __typename?: 'AggregateRateFieldsObject8v6iiYm9';
  error?: Maybe<AggregateRateFieldsObject8v6iiYm9ErrorObject>;
  items?: Maybe<Array<AggregateRateFieldsObject>>;
};

export type AggregateRateFieldsObject8v6iiYm9ErrorObject = {
  __typename?: 'AggregateRateFieldsObject8v6iiYm9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AmplitudeVariantValue {
  A = 'A',
  B = 'B',
  C = 'C',
  CONTROL = 'CONTROL',
  OFF = 'OFF'
}

export type AnswerInput = {
  answer?: InputMaybe<Scalars['String']>;
  choiceIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  questionId: Scalars['String'];
  rateId?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
};

export type AnswerObject = {
  __typename?: 'AnswerObject';
  answer?: Maybe<Scalars['String']>;
  choiceIds?: Maybe<Array<Scalars['String']>>;
  choices?: Maybe<Array<ChoiceObject>>;
  commandId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  productTicketId?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionObject>;
  questionId: Scalars['String'];
  ticketId?: Maybe<Scalars['String']>;
};

export type AnswerObject8Xa8QCg1 = {
  __typename?: 'AnswerObject8XA8QCg1';
  error?: Maybe<AnswerObject8Xa8QCg1ErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObject8Xa8QCg1ErrorObject = {
  __typename?: 'AnswerObject8XA8QCg1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObject49Bffj0p = {
  __typename?: 'AnswerObject49BFFJ0p';
  error?: Maybe<AnswerObject49Bffj0pErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObject49Bffj0pErrorObject = {
  __typename?: 'AnswerObject49BFFJ0pErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectE23vpcE6 = {
  __typename?: 'AnswerObjectE23vpcE6';
  error?: Maybe<AnswerObjectE23vpcE6ErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectE23vpcE6ErrorObject = {
  __typename?: 'AnswerObjectE23vpcE6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseListObject = {
  __typename?: 'AnswerResponseListObject';
  error?: Maybe<AnswerResponseListObjectErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerResponseListObjectErrorObject = {
  __typename?: 'AnswerResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseObject = {
  __typename?: 'AnswerResponseObject';
  error?: Maybe<AnswerResponseObjectErrorObject>;
  object?: Maybe<AnswerObject>;
};

export type AnswerResponseObjectErrorObject = {
  __typename?: 'AnswerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ApplicationInitialStatusObject = {
  __typename?: 'ApplicationInitialStatusObject';
  firstForm?: Maybe<FirstFormObject>;
  hasActiveDonationForm: Scalars['Boolean'];
  hasActiveTicketing: Scalars['Boolean'];
  hasArchivedDonationForm: Scalars['Boolean'];
  hasArchivedTicketing: Scalars['Boolean'];
  hasDonationForm: Scalars['Boolean'];
  hasTicketing: Scalars['Boolean'];
  latestForm?: Maybe<FrontendFormObject>;
};

export type ApplicationInitialStatusResponseObject = {
  __typename?: 'ApplicationInitialStatusResponseObject';
  error?: Maybe<ApplicationInitialStatusResponseObjectErrorObject>;
  object?: Maybe<ApplicationInitialStatusObject>;
};

export type ApplicationInitialStatusResponseObjectErrorObject = {
  __typename?: 'ApplicationInitialStatusResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AvailablePaymentCurrency {
  Cad = 'Cad',
  Usd = 'Usd'
}

export type BalanceObject = {
  __typename?: 'BalanceObject';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type BalanceTransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export enum BalanceTransactionType {
  issuing_authorization_hold = 'issuing_authorization_hold',
  issuing_dispute = 'issuing_dispute',
  issuing_transaction = 'issuing_transaction',
  payment = 'payment',
  payment_refund = 'payment_refund',
  payout = 'payout',
  pending_topup = 'pending_topup',
  scheduled_payout = 'scheduled_payout',
  topup = 'topup'
}

export enum BankAccountStatus {
  Errored = 'Errored',
  Verified = 'Verified'
}

export type BidRateObject = {
  __typename?: 'BidRateObject';
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type BidRateObjectJcx6grln = {
  __typename?: 'BidRateObjectJcx6grln';
  error?: Maybe<BidRateObjectJcx6grlnErrorObject>;
  items?: Maybe<Array<BidRateObject>>;
};

export type BidRateObjectJcx6grlnErrorObject = {
  __typename?: 'BidRateObjectJcx6grlnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0AfgM1Bb = {
  __typename?: 'Boolean0AfgM1BB';
  error?: Maybe<Boolean0AfgM1BbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0AfgM1BbErrorObject = {
  __typename?: 'Boolean0AfgM1BBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean3KdRnHj4 = {
  __typename?: 'Boolean3KdRNHj4';
  error?: Maybe<Boolean3KdRnHj4ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean3KdRnHj4ErrorObject = {
  __typename?: 'Boolean3KdRNHj4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean4T03Kfnc = {
  __typename?: 'Boolean4T03KFNC';
  error?: Maybe<Boolean4T03KfncErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean4T03KfncErrorObject = {
  __typename?: 'Boolean4T03KFNCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean4l5oHfzl = {
  __typename?: 'Boolean4l5oHFZL';
  error?: Maybe<Boolean4l5oHfzlErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean4l5oHfzlErrorObject = {
  __typename?: 'Boolean4l5oHFZLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean5ACp3cMc = {
  __typename?: 'Boolean5ACp3cMC';
  error?: Maybe<Boolean5ACp3cMcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean5ACp3cMcErrorObject = {
  __typename?: 'Boolean5ACp3cMCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean5pWx8lBr = {
  __typename?: 'Boolean5pWX8lBR';
  error?: Maybe<Boolean5pWx8lBrErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean5pWx8lBrErrorObject = {
  __typename?: 'Boolean5pWX8lBRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean5tIbOWui = {
  __typename?: 'Boolean5tIbOWui';
  error?: Maybe<Boolean5tIbOWuiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean5tIbOWuiErrorObject = {
  __typename?: 'Boolean5tIbOWuiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanAq5ZdSax = {
  __typename?: 'BooleanAq5ZdSax';
  error?: Maybe<BooleanAq5ZdSaxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanAq5ZdSaxErrorObject = {
  __typename?: 'BooleanAq5ZdSaxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanB1ZGeeX7 = {
  __typename?: 'BooleanB1ZGeeX7';
  error?: Maybe<BooleanB1ZGeeX7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanB1ZGeeX7ErrorObject = {
  __typename?: 'BooleanB1ZGeeX7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanBKlmiVgo = {
  __typename?: 'BooleanBKlmiVgo';
  error?: Maybe<BooleanBKlmiVgoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanBKlmiVgoErrorObject = {
  __typename?: 'BooleanBKlmiVgoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanBt8U6dpU = {
  __typename?: 'BooleanBT8U6dpU';
  error?: Maybe<BooleanBt8U6dpUErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanBt8U6dpUErrorObject = {
  __typename?: 'BooleanBT8U6dpUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanDboReyeE = {
  __typename?: 'BooleanDboReyeE';
  error?: Maybe<BooleanDboReyeEErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanDboReyeEErrorObject = {
  __typename?: 'BooleanDboReyeEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanE8d6oLy1 = {
  __typename?: 'BooleanE8d6oLy1';
  error?: Maybe<BooleanE8d6oLy1ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanE8d6oLy1ErrorObject = {
  __typename?: 'BooleanE8d6oLy1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanF8vhXs2c = {
  __typename?: 'BooleanF8vhXS2c';
  error?: Maybe<BooleanF8vhXs2cErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanF8vhXs2cErrorObject = {
  __typename?: 'BooleanF8vhXS2cErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanFhd9cmyq = {
  __typename?: 'BooleanFhd9cmyq';
  error?: Maybe<BooleanFhd9cmyqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanFhd9cmyqErrorObject = {
  __typename?: 'BooleanFhd9cmyqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanFkWcmGwq = {
  __typename?: 'BooleanFkWCMGwq';
  error?: Maybe<BooleanFkWcmGwqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanFkWcmGwqErrorObject = {
  __typename?: 'BooleanFkWCMGwqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanHEfg9Zgc = {
  __typename?: 'BooleanHEfg9ZGC';
  error?: Maybe<BooleanHEfg9ZgcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanHEfg9ZgcErrorObject = {
  __typename?: 'BooleanHEfg9ZGCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanKMp2yLw9 = {
  __typename?: 'BooleanKMp2yLw9';
  error?: Maybe<BooleanKMp2yLw9ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanKMp2yLw9ErrorObject = {
  __typename?: 'BooleanKMp2yLw9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanKehGmdAk = {
  __typename?: 'BooleanKehGmdAK';
  error?: Maybe<BooleanKehGmdAkErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanKehGmdAkErrorObject = {
  __typename?: 'BooleanKehGmdAKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanKwJdbid5 = {
  __typename?: 'BooleanKwJdbid5';
  error?: Maybe<BooleanKwJdbid5ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanKwJdbid5ErrorObject = {
  __typename?: 'BooleanKwJdbid5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanMnb2gcUe = {
  __typename?: 'BooleanMNB2gcUe';
  error?: Maybe<BooleanMnb2gcUeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanMnb2gcUeErrorObject = {
  __typename?: 'BooleanMNB2gcUeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanMnWi5BqN = {
  __typename?: 'BooleanMnWI5BqN';
  error?: Maybe<BooleanMnWi5BqNErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanMnWi5BqNErrorObject = {
  __typename?: 'BooleanMnWI5BqNErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanOBaIm17B = {
  __typename?: 'BooleanOBaIM17B';
  error?: Maybe<BooleanOBaIm17BErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanOBaIm17BErrorObject = {
  __typename?: 'BooleanOBaIM17BErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanPIb9QfrC = {
  __typename?: 'BooleanPIb9QfrC';
  error?: Maybe<BooleanPIb9QfrCErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanPIb9QfrCErrorObject = {
  __typename?: 'BooleanPIb9QfrCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanPuPflp2c = {
  __typename?: 'BooleanPuPFLP2c';
  error?: Maybe<BooleanPuPflp2cErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanPuPflp2cErrorObject = {
  __typename?: 'BooleanPuPFLP2cErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanQIdGEdCq = {
  __typename?: 'BooleanQIdGEdCq';
  error?: Maybe<BooleanQIdGEdCqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanQIdGEdCqErrorObject = {
  __typename?: 'BooleanQIdGEdCqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanRxOrOsGe = {
  __typename?: 'BooleanRXOrOsGE';
  error?: Maybe<BooleanRxOrOsGeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanRxOrOsGeErrorObject = {
  __typename?: 'BooleanRXOrOsGEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanResiJSrg = {
  __typename?: 'BooleanResiJSrg';
  error?: Maybe<BooleanResiJSrgErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanResiJSrgErrorObject = {
  __typename?: 'BooleanResiJSrgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanResponseObject = {
  __typename?: 'BooleanResponseObject';
  error?: Maybe<BooleanResponseObjectErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanResponseObjectErrorObject = {
  __typename?: 'BooleanResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanUfkVc8qP = {
  __typename?: 'BooleanUFKVc8qP';
  error?: Maybe<BooleanUfkVc8qPErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanUfkVc8qPErrorObject = {
  __typename?: 'BooleanUFKVc8qPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanV883OiId = {
  __typename?: 'BooleanV883OiID';
  error?: Maybe<BooleanV883OiIdErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanV883OiIdErrorObject = {
  __typename?: 'BooleanV883OiIDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanVKzC3Q9R = {
  __typename?: 'BooleanVKzC3Q9R';
  error?: Maybe<BooleanVKzC3Q9RErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanVKzC3Q9RErrorObject = {
  __typename?: 'BooleanVKzC3Q9RErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanVWfbzzdK = {
  __typename?: 'BooleanVWfbzzdK';
  error?: Maybe<BooleanVWfbzzdKErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanVWfbzzdKErrorObject = {
  __typename?: 'BooleanVWfbzzdKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWtCgkzoo = {
  __typename?: 'BooleanWtCgkzoo';
  error?: Maybe<BooleanWtCgkzooErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWtCgkzooErrorObject = {
  __typename?: 'BooleanWtCgkzooErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanXvXs8MNj = {
  __typename?: 'BooleanXvXs8MNj';
  error?: Maybe<BooleanXvXs8MNjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanXvXs8MNjErrorObject = {
  __typename?: 'BooleanXvXs8MNjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZ3i3zuFq = {
  __typename?: 'BooleanZ3i3zuFq';
  error?: Maybe<BooleanZ3i3zuFqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZ3i3zuFqErrorObject = {
  __typename?: 'BooleanZ3i3zuFqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZsFqCwua = {
  __typename?: 'BooleanZSFqCwua';
  error?: Maybe<BooleanZsFqCwuaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZsFqCwuaErrorObject = {
  __typename?: 'BooleanZSFqCwuaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZm8tKehi = {
  __typename?: 'BooleanZm8tKehi';
  error?: Maybe<BooleanZm8tKehiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZm8tKehiErrorObject = {
  __typename?: 'BooleanZm8tKehiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanaKmQa4Dg = {
  __typename?: 'BooleanaKmQa4DG';
  error?: Maybe<BooleanaKmQa4DgErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanaKmQa4DgErrorObject = {
  __typename?: 'BooleanaKmQa4DGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanemSGtzTj = {
  __typename?: 'BooleanemSGtzTJ';
  error?: Maybe<BooleanemSGtzTjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanemSGtzTjErrorObject = {
  __typename?: 'BooleanemSGtzTJErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanesbWka3y = {
  __typename?: 'BooleanesbWKA3y';
  error?: Maybe<BooleanesbWka3yErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanesbWka3yErrorObject = {
  __typename?: 'BooleanesbWKA3yErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleangl19elyk = {
  __typename?: 'Booleangl19elyk';
  error?: Maybe<Booleangl19elykErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleangl19elykErrorObject = {
  __typename?: 'Booleangl19elykErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleaniHxSkK7g = {
  __typename?: 'BooleaniHXSkK7g';
  error?: Maybe<BooleaniHxSkK7gErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleaniHxSkK7gErrorObject = {
  __typename?: 'BooleaniHXSkK7gErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleankYbme3Qc = {
  __typename?: 'BooleankYBME3Qc';
  error?: Maybe<BooleankYbme3QcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleankYbme3QcErrorObject = {
  __typename?: 'BooleankYBME3QcErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanl3aWyxgp = {
  __typename?: 'Booleanl3aWYXGP';
  error?: Maybe<Booleanl3aWyxgpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanl3aWyxgpErrorObject = {
  __typename?: 'Booleanl3aWYXGPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanlXfVcTc5 = {
  __typename?: 'BooleanlXFVcTc5';
  error?: Maybe<BooleanlXfVcTc5ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanlXfVcTc5ErrorObject = {
  __typename?: 'BooleanlXFVcTc5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannJlfMgBd = {
  __typename?: 'BooleannJlfMGBd';
  error?: Maybe<BooleannJlfMgBdErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannJlfMgBdErrorObject = {
  __typename?: 'BooleannJlfMGBdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannzkFDiTc = {
  __typename?: 'BooleannzkFDiTc';
  error?: Maybe<BooleannzkFDiTcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannzkFDiTcErrorObject = {
  __typename?: 'BooleannzkFDiTcErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpfhGTw78 = {
  __typename?: 'BooleanpfhGTw78';
  error?: Maybe<BooleanpfhGTw78ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpfhGTw78ErrorObject = {
  __typename?: 'BooleanpfhGTw78ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpixDKwE7 = {
  __typename?: 'BooleanpixDKwE7';
  error?: Maybe<BooleanpixDKwE7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpixDKwE7ErrorObject = {
  __typename?: 'BooleanpixDKwE7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpoBiCoAn = {
  __typename?: 'BooleanpoBiCoAN';
  error?: Maybe<BooleanpoBiCoAnErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpoBiCoAnErrorObject = {
  __typename?: 'BooleanpoBiCoANErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanqX9Ny8xl = {
  __typename?: 'BooleanqX9Ny8xl';
  error?: Maybe<BooleanqX9Ny8xlErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanqX9Ny8xlErrorObject = {
  __typename?: 'BooleanqX9Ny8xlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanqc61ytTb = {
  __typename?: 'Booleanqc61ytTB';
  error?: Maybe<Booleanqc61ytTbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanqc61ytTbErrorObject = {
  __typename?: 'Booleanqc61ytTBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanrQkyRpmw = {
  __typename?: 'BooleanrQkyRPMW';
  error?: Maybe<BooleanrQkyRpmwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanrQkyRpmwErrorObject = {
  __typename?: 'BooleanrQkyRPMWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanseHk8chb = {
  __typename?: 'BooleanseHk8chb';
  error?: Maybe<BooleanseHk8chbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanseHk8chbErrorObject = {
  __typename?: 'BooleanseHk8chbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleantOjx1czI = {
  __typename?: 'BooleantOJX1czI';
  error?: Maybe<BooleantOjx1czIErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleantOjx1czIErrorObject = {
  __typename?: 'BooleantOJX1czIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanu1OoIcmA = {
  __typename?: 'Booleanu1OoIcmA';
  error?: Maybe<Booleanu1OoIcmAErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanu1OoIcmAErrorObject = {
  __typename?: 'Booleanu1OoIcmAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanunssNo98 = {
  __typename?: 'BooleanunssNo98';
  error?: Maybe<BooleanunssNo98ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanunssNo98ErrorObject = {
  __typename?: 'BooleanunssNo98ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanwPWrPlAw = {
  __typename?: 'BooleanwPWrPlAw';
  error?: Maybe<BooleanwPWrPlAwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanwPWrPlAwErrorObject = {
  __typename?: 'BooleanwPWrPlAwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanxao1YXp6 = {
  __typename?: 'Booleanxao1YXp6';
  error?: Maybe<Booleanxao1YXp6ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanxao1YXp6ErrorObject = {
  __typename?: 'Booleanxao1YXp6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxqpuaBjH = {
  __typename?: 'BooleanxqpuaBjH';
  error?: Maybe<BooleanxqpuaBjHErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxqpuaBjHErrorObject = {
  __typename?: 'BooleanxqpuaBjHErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleany9Z2hguA = {
  __typename?: 'Booleany9Z2hguA';
  error?: Maybe<Booleany9Z2hguAErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleany9Z2hguAErrorObject = {
  __typename?: 'Booleany9Z2hguAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanyrbv5Npp = {
  __typename?: 'Booleanyrbv5Npp';
  error?: Maybe<Booleanyrbv5NppErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanyrbv5NppErrorObject = {
  __typename?: 'Booleanyrbv5NppErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanyy9IdbqI = {
  __typename?: 'Booleanyy9IdbqI';
  error?: Maybe<Booleanyy9IdbqIErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanyy9IdbqIErrorObject = {
  __typename?: 'Booleanyy9IdbqIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzMr2ftAs = {
  __typename?: 'BooleanzMR2ftAs';
  error?: Maybe<BooleanzMr2ftAsErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzMr2ftAsErrorObject = {
  __typename?: 'BooleanzMR2ftAsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzPn6SqRf = {
  __typename?: 'BooleanzPn6SQRf';
  error?: Maybe<BooleanzPn6SqRfErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzPn6SqRfErrorObject = {
  __typename?: 'BooleanzPn6SQRfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CardHolderStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Inactive = 'Inactive'
}

export type CardVolumeObject = {
  __typename?: 'CardVolumeObject';
  amount: Scalars['Float'];
  count: Scalars['Float'];
};

export type CardVolumeResponseObject = {
  __typename?: 'CardVolumeResponseObject';
  error?: Maybe<CardVolumeResponseObjectErrorObject>;
  object?: Maybe<CardVolumeObject>;
};

export type CardVolumeResponseObjectErrorObject = {
  __typename?: 'CardVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CheckIfResetPasswordIsValidInput = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type CheckoutSessionObject = {
  __typename?: 'CheckoutSessionObject';
  cancelUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CheckoutSessionResponseObject = {
  __typename?: 'CheckoutSessionResponseObject';
  error?: Maybe<CheckoutSessionResponseObjectErrorObject>;
  object?: Maybe<CheckoutSessionObject>;
};

export type CheckoutSessionResponseObjectErrorObject = {
  __typename?: 'CheckoutSessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ChoiceFieldInput = {
  body: Scalars['String'];
  choiceId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceFieldObject = {
  __typename?: 'ChoiceFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceInput = {
  choiceFields: Array<ChoiceFieldInput>;
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  maximum?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChoiceObject = {
  __typename?: 'ChoiceObject';
  choiceFields: Array<ChoiceFieldObject>;
  countAlreadyAnswered?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  maximum?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChunkImportObject = {
  __typename?: 'ChunkImportObject';
  chunkIndex: Scalars['Float'];
  zeffyImport: ZeffyImportObject;
};

export type ChunkImportObjectVTi1ANkn = {
  __typename?: 'ChunkImportObjectVTi1ANkn';
  error?: Maybe<ChunkImportObjectVTi1ANknErrorObject>;
  object?: Maybe<ChunkImportObject>;
};

export type ChunkImportObjectVTi1ANknErrorObject = {
  __typename?: 'ChunkImportObjectVTi1ANknErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ClusterObject = {
  __typename?: 'ClusterObject';
  coordinates: CoordinatesObject;
  count: Scalars['Float'];
  cursorType: CursorType;
  displayedLatitude: Scalars['Float'];
  displayedLongitude: Scalars['Float'];
  id: Scalars['String'];
};

export type ClusterResponseObject = {
  __typename?: 'ClusterResponseObject';
  error?: Maybe<ClusterResponseObjectErrorObject>;
  items?: Maybe<Array<ClusterObject>>;
};

export type ClusterResponseObjectErrorObject = {
  __typename?: 'ClusterResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandLang {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type CommandObject = {
  __typename?: 'CommandObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAtUtc: Scalars['Date'];
  cs?: Maybe<Scalars['String']>;
  currency: AvailablePaymentCurrency;
  discount?: Maybe<DiscountObject>;
  discountAmount: Scalars['Int'];
  discountId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  eligibleAmount: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formLang: CommandLang;
  formType: FormType;
  id: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  locale: Locales;
  organization: OrganizationObject;
  organizationCountry: Scalars['String'];
  organizationId: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  postalCode?: Maybe<Scalars['String']>;
  productBids?: Maybe<Array<ProductBidObject>>;
  productDonation?: Maybe<ProductDonationObject>;
  productTickets?: Maybe<Array<ProductTicketObject>>;
  productsAmount: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  source: CommandSource;
  status: CommandStatus;
  tipAmount: Scalars['Int'];
  tipPercentage: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type CommandObjectBgbN4l2a = {
  __typename?: 'CommandObjectBgbN4l2a';
  error?: Maybe<CommandObjectBgbN4l2aErrorObject>;
  items?: Maybe<Array<CommandObject>>;
};

export type CommandObjectBgbN4l2aErrorObject = {
  __typename?: 'CommandObjectBgbN4l2aErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectJzc26wLq = {
  __typename?: 'CommandObjectJZC26wLQ';
  error?: Maybe<CommandObjectJzc26wLqErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectJzc26wLqErrorObject = {
  __typename?: 'CommandObjectJZC26wLQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectMMzqMyFs = {
  __typename?: 'CommandObjectMMzqMYFs';
  error?: Maybe<CommandObjectMMzqMyFsErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectMMzqMyFsErrorObject = {
  __typename?: 'CommandObjectMMzqMYFsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectXmcEaesL = {
  __typename?: 'CommandObjectXmcEaesL';
  error?: Maybe<CommandObjectXmcEaesLErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectXmcEaesLErrorObject = {
  __typename?: 'CommandObjectXmcEaesLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectbeyhhTzQ = {
  __typename?: 'CommandObjectbeyhhTzQ';
  error?: Maybe<CommandObjectbeyhhTzQErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectbeyhhTzQErrorObject = {
  __typename?: 'CommandObjectbeyhhTzQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectv9R8S6Lz = {
  __typename?: 'CommandObjectv9R8S6Lz';
  error?: Maybe<CommandObjectv9R8S6LzErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectv9R8S6LzErrorObject = {
  __typename?: 'CommandObjectv9R8S6LzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseListObjectErrorObject = {
  __typename?: 'CommandResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseObject = {
  __typename?: 'CommandResponseObject';
  error?: Maybe<CommandResponseObjectErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandResponseObjectErrorObject = {
  __typename?: 'CommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandSource {
  FormSubmission = 'FormSubmission',
  Import = 'Import',
  ManualEntry = 'ManualEntry'
}

export enum CommandStatus {
  Archived = 'Archived',
  Complete = 'Complete',
  Expired = 'Expired',
  Open = 'Open',
  Submitted = 'Submitted'
}

export type CommandSubscriptionObject = {
  __typename?: 'CommandSubscriptionObject';
  id: Scalars['String'];
  recurrenceInterval: SubscriptionRecurrenceInterval;
  status?: Maybe<NewSubscriptionStatus>;
  stripeSubscriptionId: Scalars['String'];
};

export type CommandSubscriptionResponseObject = {
  __typename?: 'CommandSubscriptionResponseObject';
  error?: Maybe<CommandSubscriptionResponseObjectErrorObject>;
  object?: Maybe<CommandSubscriptionObject>;
};

export type CommandSubscriptionResponseObjectErrorObject = {
  __typename?: 'CommandSubscriptionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CompleteStripeAuthError {
  ImpossibleToCreateStripeAccount = 'ImpossibleToCreateStripeAccount'
}

export type CompleteStripeAuthResponseObjectErrorObject = {
  __typename?: 'CompleteStripeAuthResponseObjectErrorObject';
  code?: Maybe<CompleteStripeAuthError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ConfirmPaymentIntentInput = {
  paymentIntentId: Scalars['String'];
};

export type ConfirmPaymentIntentObject = {
  __typename?: 'ConfirmPaymentIntentObject';
  nextAction?: Maybe<NextActionObject>;
};

export type ConfirmPaymentIntentObjectResponseObjectErrorObject = {
  __typename?: 'ConfirmPaymentIntentObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactIdWithTagsObject = {
  __typename?: 'ContactIdWithTagsObject';
  id: Scalars['String'];
  tags: Array<TagObject>;
};

export type ContactIdWithTagsObjectbXvzDxy0 = {
  __typename?: 'ContactIdWithTagsObjectbXvzDxy0';
  error?: Maybe<ContactIdWithTagsObjectbXvzDxy0ErrorObject>;
  items?: Maybe<Array<ContactIdWithTagsObject>>;
};

export type ContactIdWithTagsObjectbXvzDxy0ErrorObject = {
  __typename?: 'ContactIdWithTagsObjectbXvzDxy0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactKeyInfoObject = {
  __typename?: 'ContactKeyInfoObject';
  contactSince?: Maybe<Scalars['Date']>;
  largestDonation?: Maybe<Scalars['Float']>;
  monthlyRecurringContribution?: Maybe<Scalars['Float']>;
  totalContribution?: Maybe<Scalars['Float']>;
  yearlyRecurringContribution?: Maybe<Scalars['Float']>;
};

export type ContactKeyInfoObjectxRb355qy = {
  __typename?: 'ContactKeyInfoObjectxRB355qy';
  error?: Maybe<ContactKeyInfoObjectxRb355qyErrorObject>;
  object?: Maybe<ContactKeyInfoObject>;
};

export type ContactKeyInfoObjectxRb355qyErrorObject = {
  __typename?: 'ContactKeyInfoObjectxRB355qyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObject = {
  __typename?: 'ContactObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  donorType?: Maybe<DonorType>;
  firstName?: Maybe<Scalars['String']>;
  hasUnsubscribed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  unsubscribedEmailDeliveryId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  userId: Scalars['String'];
};

export type ContactObject2k5aFgTo = {
  __typename?: 'ContactObject2k5aFgTo';
  error?: Maybe<ContactObject2k5aFgToErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObject2k5aFgToErrorObject = {
  __typename?: 'ContactObject2k5aFgToErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObject6zpUg36D = {
  __typename?: 'ContactObject6zpUG36D';
  error?: Maybe<ContactObject6zpUg36DErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObject6zpUg36DErrorObject = {
  __typename?: 'ContactObject6zpUG36DErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectIrYfiuit = {
  __typename?: 'ContactObjectIrYFIUIT';
  error?: Maybe<ContactObjectIrYfiuitErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectIrYfiuitErrorObject = {
  __typename?: 'ContactObjectIrYFIUITErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectas8fyPop = {
  __typename?: 'ContactObjectas8fyPop';
  error?: Maybe<ContactObjectas8fyPopErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectas8fyPopErrorObject = {
  __typename?: 'ContactObjectas8fyPopErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactResponseObject = {
  __typename?: 'ContactResponseObject';
  error?: Maybe<ContactResponseObjectErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactResponseObjectErrorObject = {
  __typename?: 'ContactResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CoordinatesObject = {
  __typename?: 'CoordinatesObject';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateCardHolderInput = {
  address: Scalars['String'];
  agreedToStripeCardIssuingTermsAt: Scalars['Date'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: DateOfBirthInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type CreateDonationAmountInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isRecurrent?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval?: InputMaybe<ProductDonationRecurrenceInterval>;
  sortIndex?: InputMaybe<Scalars['Float']>;
};

export type CreateDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts: Array<CreateDonationAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  postDonationUrl?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateDraftDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  displayAddressQuestion?: InputMaybe<Scalars['Boolean']>;
  donationFormFields: Array<CreateDonationFormFieldInput>;
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  eventFollowupEmailContentId?: InputMaybe<Scalars['String']>;
  eventInvitationEmailContentId?: InputMaybe<Scalars['String']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isOnboardingForm?: Scalars['Boolean'];
  isPrimaryCampaign?: InputMaybe<Scalars['Boolean']>;
  isPrimaryTeam?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<CreateLinkedTicketingInput>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  organizationId?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  registrationCommandId?: InputMaybe<Scalars['String']>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  registrationOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FormStatus>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['Float']>;
  teamId?: InputMaybe<Scalars['String']>;
  ticketingId?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type CreateFrontendOrderResponseObjectErrorObject = {
  __typename?: 'CreateFrontendOrderResponseObjectErrorObject';
  code?: Maybe<CreateOrderResponseError>;
  data?: Maybe<CreateOrderErrorDataObject>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateIssuingTopupInput = {
  amount: Scalars['Int'];
  plaidAccountId?: InputMaybe<Scalars['String']>;
  plaidPublicToken?: InputMaybe<Scalars['String']>;
};

export type CreateLinkedTicketingInput = {
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  rates: Array<CreateRateInput>;
};

export type CreateMobileCommandInput = {
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  extraDonation: Scalars['Float'];
  formId: Scalars['String'];
  locale: Locales;
  paymentIntentId: Scalars['String'];
  productDonation?: InputMaybe<CreateMobileProductDonationInput>;
  productTickets?: InputMaybe<Array<CreateMobileTicketInput>>;
  productsAmount: Scalars['Int'];
  tipAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

export type CreateMobileCommandObject = {
  __typename?: 'CreateMobileCommandObject';
  id: Scalars['String'];
};

export type CreateMobileCommandResponseObject = {
  __typename?: 'CreateMobileCommandResponseObject';
  error?: Maybe<CreateMobileCommandResponseObjectErrorObject>;
  object?: Maybe<CreateMobileCommandObject>;
};

export type CreateMobileCommandResponseObjectErrorObject = {
  __typename?: 'CreateMobileCommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateMobileProductDonationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  inHonourEmailAddress?: InputMaybe<Scalars['String']>;
  inHonourEmailBody?: InputMaybe<Scalars['String']>;
  inHonourName?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: InputMaybe<Scalars['String']>;
};

export type CreateMobileTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal: Scalars['Boolean'];
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
};

export type CreateNewOrganizationInput = {
  country: OrganizationCountry;
  organizationName: Scalars['String'];
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOther: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrderErrorDataObject = {
  __typename?: 'CreateOrderErrorDataObject';
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers: Array<AnswerInput>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  email?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Float'];
  firstName?: InputMaybe<Scalars['String']>;
  isTapToPay?: InputMaybe<Scalars['Boolean']>;
  isTipMessageStayFree?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  occurrenceId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  paymentIntentId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  tip: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export enum CreateOrderResponseError {
  ChoiceIsFull = 'ChoiceIsFull',
  ExpiredEarlyBirdRate = 'ExpiredEarlyBirdRate',
  NoTicketNorExtraDonation = 'NoTicketNorExtraDonation',
  NoTicketSelected = 'NoTicketSelected',
  OverpassedTicketingSeats = 'OverpassedTicketingSeats',
  RateIsFull = 'RateIsFull',
  ShouldSpecifyATicketingOccurrence = 'ShouldSpecifyATicketingOccurrence',
  TicketingIsClosed = 'TicketingIsClosed',
  TicketingIsFull = 'TicketingIsFull',
  TicketingOccurrenceNotFound = 'TicketingOccurrenceNotFound',
  UnknownError = 'UnknownError'
}

export type CreateOrderResponseObject = {
  __typename?: 'CreateOrderResponseObject';
  error?: Maybe<CreateOrderResponseError>;
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  order?: Maybe<OrderObject>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  locale: Locales;
  title: Scalars['String'];
};

export type CreateRateInput = {
  amount: Scalars['Float'];
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photoUrls?: InputMaybe<Array<Scalars['String']>>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields?: InputMaybe<Array<CreateRateFieldInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type CreateReceiptInput = {
  address?: InputMaybe<Scalars['String']>;
  advantageAmount?: InputMaybe<Scalars['Float']>;
  advantageDescription?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  annotation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  donationDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isImported?: InputMaybe<Scalars['Boolean']>;
  isSample?: InputMaybe<Scalars['Boolean']>;
  issuingAtUtc?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  postalCode?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReceiptStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTagInput = {
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal?: InputMaybe<Scalars['Boolean']>;
  rateId: Scalars['String'];
};

export type CreateTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  postEventBody?: InputMaybe<Scalars['String']>;
  postEventSubject?: InputMaybe<Scalars['String']>;
  postTransactionUrl?: InputMaybe<Scalars['String']>;
  reminderBody?: InputMaybe<Scalars['String']>;
  reminderSubject?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  duplicatedFromId?: InputMaybe<Scalars['String']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isOnboardingForm?: InputMaybe<Scalars['Boolean']>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale: Locales;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder: Array<QuestionInput>;
  questionsPerParticipant: Array<QuestionInput>;
  rates: Array<CreateRateInput>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  reminderSendDateOffset?: InputMaybe<Scalars['Float']>;
  seats?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<FormStatus>;
  ticketingFields: Array<CreateTicketingFieldInput>;
};

export type CreatedCampaignsObject = {
  __typename?: 'CreatedCampaignsObject';
  individualCampaigns?: Maybe<Array<FundraiserDonationFormObject>>;
  team?: Maybe<FundraiserDonationFormObject>;
};

export type CreatedIssuingPayoutInput = {
  amount: Scalars['Float'];
};

export type CreatedIssuingPayoutObject = {
  __typename?: 'CreatedIssuingPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type CreatedIssuingPayoutObject6wIgd7oi = {
  __typename?: 'CreatedIssuingPayoutObject6wIGD7oi';
  error?: Maybe<CreatedIssuingPayoutObject6wIgd7oiErrorObject>;
  object?: Maybe<CreatedIssuingPayoutObject>;
};

export type CreatedIssuingPayoutObject6wIgd7oiErrorObject = {
  __typename?: 'CreatedIssuingPayoutObject6wIGD7oiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CronQueues {
  AuctionsPaymentProcessor = 'AuctionsPaymentProcessor',
  CloseAuctions = 'CloseAuctions',
  GenerateBankStatement = 'GenerateBankStatement',
  GenerateSitemap = 'GenerateSitemap',
  HubspotNurturingDealCreation = 'HubspotNurturingDealCreation',
  InvitationReminder = 'InvitationReminder',
  MarkStaleTransactionsAsFailed = 'MarkStaleTransactionsAsFailed',
  MembershipReminders = 'MembershipReminders',
  NotifyActiveOrganizationsWithoutBankInfo = 'NotifyActiveOrganizationsWithoutBankInfo',
  ProcessBalanceTransfersToIssuing = 'ProcessBalanceTransfersToIssuing',
  ProcessIssuingRewards = 'ProcessIssuingRewards',
  ProcessReferralTransfers = 'ProcessReferralTransfers',
  ProcessScheduledPayouts = 'ProcessScheduledPayouts',
  Reminders = 'Reminders',
  RetryFailedTransfer = 'RetryFailedTransfer',
  SendDailyVisitorReport = 'SendDailyVisitorReport',
  SendEventManagementEmails = 'SendEventManagementEmails',
  SendMonthlyBalanceTransactionsExport = 'SendMonthlyBalanceTransactionsExport',
  SendMonthlyChurnedReport = 'SendMonthlyChurnedReport',
  SendPostEventEmailDeliveries = 'SendPostEventEmailDeliveries',
  SendScheduledEmailDeliveries = 'SendScheduledEmailDeliveries',
  SendSuggestDesktopCompletionEmail = 'SendSuggestDesktopCompletionEmail',
  SendWeeklyChurnedReport = 'SendWeeklyChurnedReport',
  TriggerAwaitingWithdrawalRefunds = 'TriggerAwaitingWithdrawalRefunds',
  UpdateFraudulentTransaction = 'UpdateFraudulentTransaction',
  UpdateMissingIrsInfo = 'UpdateMissingIrsInfo'
}

export type CurrentlyActiveOrganizationObject = {
  __typename?: 'CurrentlyActiveOrganizationObject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CurrentlyActiveOrganizationResponseListObject = {
  __typename?: 'CurrentlyActiveOrganizationResponseListObject';
  error?: Maybe<OrganizationResponseListObjectErrorObject>;
  items?: Maybe<Array<CurrentlyActiveOrganizationObject>>;
};

export enum CursorType {
  Organization = 'Organization',
  Ticketing = 'Ticketing'
}

export type DashboardCommandFormListResponse = {
  __typename?: 'DashboardCommandFormListResponse';
  error?: Maybe<DashboardCommandFormListResponseErrorObject>;
  items?: Maybe<Array<FormObject>>;
};

export type DashboardCommandFormListResponseErrorObject = {
  __typename?: 'DashboardCommandFormListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRateObject = {
  __typename?: 'DashboardCommandRateObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DashboardCommandRatesListResponseObject = {
  __typename?: 'DashboardCommandRatesListResponseObject';
  error?: Maybe<DashboardCommandRatesListResponseObjectErrorObject>;
  items?: Maybe<Array<DashboardCommandRatesObject>>;
};

export type DashboardCommandRatesListResponseObjectErrorObject = {
  __typename?: 'DashboardCommandRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRatesObject = {
  __typename?: 'DashboardCommandRatesObject';
  commandId: Scalars['String'];
  rates: Array<DashboardCommandRateObject>;
};

export type DashboardCountObject = {
  __typename?: 'DashboardCountObject';
  count: Scalars['Float'];
};

export type DashboardCountObjectGNquviGe = {
  __typename?: 'DashboardCountObjectGNquviGE';
  error?: Maybe<DashboardCountObjectGNquviGeErrorObject>;
  object?: Maybe<DashboardCountObject>;
};

export type DashboardCountObjectGNquviGeErrorObject = {
  __typename?: 'DashboardCountObjectGNquviGEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCountResponseObjectErrorObject = {
  __typename?: 'DashboardCountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DashboardPaymentStatus {
  DisputeLost = 'DisputeLost',
  Disputed = 'Disputed',
  Failed = 'Failed',
  Incomplete = 'Incomplete',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  PastDue = 'PastDue',
  Processing = 'Processing',
  Stopped = 'Stopped',
  Succeeded = 'Succeeded',
  Unprocessed = 'Unprocessed',
  Yearly = 'Yearly'
}

export type DashboardTransactionListResponseObjectErrorObject = {
  __typename?: 'DashboardTransactionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObject = {
  __typename?: 'DashboardTransactionObject';
  commandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  displayStatus: DashboardPaymentStatus;
  email?: Maybe<Scalars['String']>;
  eventEndAt?: Maybe<Scalars['Date']>;
  eventStartAt?: Maybe<Scalars['Date']>;
  extraDonation?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formType: FormType;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isNewRecurringDonation?: Maybe<Scalars['Boolean']>;
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nextRenewalAt?: Maybe<Scalars['Date']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payoutDate?: Maybe<Scalars['Date']>;
  receiptId?: Maybe<Scalars['String']>;
  recurrenceInterval?: Maybe<SubscriptionRecurrenceInterval>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount: Scalars['Int'];
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status: PaymentStatus;
  stoppedAt?: Maybe<Scalars['Date']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<NewSubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type DashboardTransactionObject043qfsTb = {
  __typename?: 'DashboardTransactionObject043qfsTB';
  error?: Maybe<DashboardTransactionObject043qfsTbErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObject043qfsTbErrorObject = {
  __typename?: 'DashboardTransactionObject043qfsTBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObjectWy3WdGcO = {
  __typename?: 'DashboardTransactionObjectWY3WDGcO';
  error?: Maybe<DashboardTransactionObjectWy3WdGcOErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectWy3WdGcOErrorObject = {
  __typename?: 'DashboardTransactionObjectWY3WDGcOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DateOfBirthInput = {
  day: Scalars['Float'];
  month: Scalars['Float'];
  year: Scalars['Float'];
};

export type DeactivateAccountInput = {
  reason: Scalars['String'];
};

export type DeactiveTwoFactorAuthInput = {
  password: Scalars['String'];
};

export type DeleteUserAccountInput = {
  reason: Scalars['String'];
};

export type DiscountObject = {
  __typename?: 'DiscountObject';
  amount: Scalars['Float'];
  body: Scalars['String'];
  id: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  remainingUses?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  ticketingId: Scalars['String'];
  type: DiscountType;
};

export type DiscountResponseObject = {
  __typename?: 'DiscountResponseObject';
  error?: Maybe<DiscountResponseObjectErrorObject>;
  object?: Maybe<DiscountObject>;
};

export type DiscountResponseObjectErrorObject = {
  __typename?: 'DiscountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DiscountType {
  Dollars = 'Dollars',
  Percentage = 'Percentage'
}

export type DonationFormAmountInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormAmountObject = {
  __typename?: 'DonationFormAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormCampaignFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  search: Scalars['String'];
};

export enum DonationFormCategory {
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  PeerToPeerCampaign = 'PeerToPeerCampaign'
}

export type DonationFormFieldFromCampaignObject = {
  __typename?: 'DonationFormFieldFromCampaignObject';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type DonationFormFieldObject = {
  __typename?: 'DonationFormFieldObject';
  chequeDescription?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  description: Scalars['String'];
  donationFormAmounts?: Maybe<Array<DonationFormAmountObject>>;
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<Scalars['String']>;
  emailObject?: Maybe<Scalars['String']>;
  fundraiserEmailBody?: Maybe<Scalars['String']>;
  fundraisingRegistrationWording?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  postDonationUrl?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  ticketingRegistrationWording?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DonationFormFromCampaignObject = {
  __typename?: 'DonationFormFromCampaignObject';
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  donationFormFields?: Maybe<Array<DonationFormFieldFromCampaignObject>>;
  id: Scalars['String'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject = {
  __typename?: 'DonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  eventFollowupEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailContentId?: Maybe<Scalars['String']>;
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject3ymcGqbK = {
  __typename?: 'DonationFormObject3ymcGqbK';
  error?: Maybe<DonationFormObject3ymcGqbKErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject3ymcGqbKErrorObject = {
  __typename?: 'DonationFormObject3ymcGqbKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject4hyGOivq = {
  __typename?: 'DonationFormObject4hyGOivq';
  error?: Maybe<DonationFormObject4hyGOivqErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObject4hyGOivqErrorObject = {
  __typename?: 'DonationFormObject4hyGOivqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject5bdAxsBj = {
  __typename?: 'DonationFormObject5bdAXSBj';
  error?: Maybe<DonationFormObject5bdAxsBjErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject5bdAxsBjErrorObject = {
  __typename?: 'DonationFormObject5bdAXSBjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject6CslF9Wf = {
  __typename?: 'DonationFormObject6CslF9WF';
  error?: Maybe<DonationFormObject6CslF9WfErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject6CslF9WfErrorObject = {
  __typename?: 'DonationFormObject6CslF9WFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject92Mfevvr = {
  __typename?: 'DonationFormObject92MFEVVR';
  error?: Maybe<DonationFormObject92MfevvrErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject92MfevvrErrorObject = {
  __typename?: 'DonationFormObject92MFEVVRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectDc0Wx3Db = {
  __typename?: 'DonationFormObjectDc0WX3Db';
  error?: Maybe<DonationFormObjectDc0Wx3DbErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectDc0Wx3DbErrorObject = {
  __typename?: 'DonationFormObjectDc0WX3DbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectExp213cr = {
  __typename?: 'DonationFormObjectEXP213cr';
  error?: Maybe<DonationFormObjectExp213crErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectExp213crErrorObject = {
  __typename?: 'DonationFormObjectEXP213crErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectIzszSvra = {
  __typename?: 'DonationFormObjectIzszSVRA';
  error?: Maybe<DonationFormObjectIzszSvraErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectIzszSvraErrorObject = {
  __typename?: 'DonationFormObjectIzszSVRAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectJv0gc9qD = {
  __typename?: 'DonationFormObjectJV0gc9qD';
  error?: Maybe<DonationFormObjectJv0gc9qDErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectJv0gc9qDErrorObject = {
  __typename?: 'DonationFormObjectJV0gc9qDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectKMziD9Br = {
  __typename?: 'DonationFormObjectKMziD9BR';
  error?: Maybe<DonationFormObjectKMziD9BrErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectKMziD9BrErrorObject = {
  __typename?: 'DonationFormObjectKMziD9BRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectLhW1rvRa = {
  __typename?: 'DonationFormObjectLhW1rvRA';
  error?: Maybe<DonationFormObjectLhW1rvRaErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectLhW1rvRaErrorObject = {
  __typename?: 'DonationFormObjectLhW1rvRAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectTnGocCRr = {
  __typename?: 'DonationFormObjectTNGocCRr';
  error?: Maybe<DonationFormObjectTnGocCRrErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectTnGocCRrErrorObject = {
  __typename?: 'DonationFormObjectTNGocCRrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectTsQxml1m = {
  __typename?: 'DonationFormObjectTsQXML1m';
  error?: Maybe<DonationFormObjectTsQxml1mErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObjectTsQxml1mErrorObject = {
  __typename?: 'DonationFormObjectTsQXML1mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectZMyX8Vou = {
  __typename?: 'DonationFormObjectZMyX8VOU';
  error?: Maybe<DonationFormObjectZMyX8VouErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectZMyX8VouErrorObject = {
  __typename?: 'DonationFormObjectZMyX8VOUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectZepdrNdV = {
  __typename?: 'DonationFormObjectZepdrNdV';
  error?: Maybe<DonationFormObjectZepdrNdVErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectZepdrNdVErrorObject = {
  __typename?: 'DonationFormObjectZepdrNdVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectat41XcXp = {
  __typename?: 'DonationFormObjectat41XCXp';
  error?: Maybe<DonationFormObjectat41XcXpErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectat41XcXpErrorObject = {
  __typename?: 'DonationFormObjectat41XCXpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectcQcnekNl = {
  __typename?: 'DonationFormObjectcQcnekNL';
  error?: Maybe<DonationFormObjectcQcnekNlErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectcQcnekNlErrorObject = {
  __typename?: 'DonationFormObjectcQcnekNLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectoL5n8Atm = {
  __typename?: 'DonationFormObjectoL5n8Atm';
  error?: Maybe<DonationFormObjectoL5n8AtmErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectoL5n8AtmErrorObject = {
  __typename?: 'DonationFormObjectoL5n8AtmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectp21HYhoU = {
  __typename?: 'DonationFormObjectp21HYhoU';
  error?: Maybe<DonationFormObjectp21HYhoUErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectp21HYhoUErrorObject = {
  __typename?: 'DonationFormObjectp21HYhoUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectsNwXqmPr = {
  __typename?: 'DonationFormObjectsNWXqmPr';
  error?: Maybe<DonationFormObjectsNwXqmPrErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectsNwXqmPrErrorObject = {
  __typename?: 'DonationFormObjectsNWXqmPrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectv7lcFFtk = {
  __typename?: 'DonationFormObjectv7lcFFtk';
  error?: Maybe<DonationFormObjectv7lcFFtkErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectv7lcFFtkErrorObject = {
  __typename?: 'DonationFormObjectv7lcFFtkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectvMuwu4Cl = {
  __typename?: 'DonationFormObjectvMuwu4Cl';
  error?: Maybe<DonationFormObjectvMuwu4ClErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectvMuwu4ClErrorObject = {
  __typename?: 'DonationFormObjectvMuwu4ClErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormRecurrence {
  monthly = 'monthly',
  weekly = 'weekly'
}

export type DonationFormResponseObject = {
  __typename?: 'DonationFormResponseObject';
  error?: Maybe<DonationFormResponseObjectErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormResponseObjectErrorObject = {
  __typename?: 'DonationFormResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormSummaryObject = {
  __typename?: 'DonationFormSummaryObject';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type DonationFormTableResponseListObjectErrorObject = {
  __typename?: 'DonationFormTableResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormTemplateType {
  givingTuesday = 'givingTuesday',
  onboarding = 'onboarding'
}

export type DonationObject = {
  __typename?: 'DonationObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationForm: DonationFormObject;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastTransactionId?: Maybe<Scalars['String']>;
  nextPaymentDate?: Maybe<Scalars['Date']>;
  postalCode?: Maybe<Scalars['String']>;
  recurrence?: Maybe<DonationFormRecurrence>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  region?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type DonationResponseListObjectErrorObject = {
  __typename?: 'DonationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationResponseObjectErrorObject = {
  __typename?: 'DonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationCountry: OrganizationCountry;
};

export enum DonorType {
  Member = 'Member',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Prospect = 'Prospect',
  Returning = 'Returning',
  Yearly = 'Yearly'
}

export type DonorVolumeListResponseObject = {
  __typename?: 'DonorVolumeListResponseObject';
  error?: Maybe<DonorVolumeListResponseObjectErrorObject>;
  items?: Maybe<Array<DonorVolumeObject>>;
};

export type DonorVolumeListResponseObjectErrorObject = {
  __typename?: 'DonorVolumeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorVolumeObject = {
  __typename?: 'DonorVolumeObject';
  amount: Scalars['Float'];
  currency: AvailablePaymentCurrency;
};

export type EditContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type EditDiscountInput = {
  amount: Scalars['Float'];
  body: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  type: DiscountType;
};

export type EditDonationFormAmountInput = {
  toDelete: Array<DonationFormAmountInput>;
  toSave: Array<UpdateDonationFormAmountInput>;
};

export type EditDonationFormAmountTranslationInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type EditDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  donationFormFields: Array<EditDonationFormFieldInput>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<EditLinkedTicketingInput>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrence?: InputMaybe<DonationFormRecurrence>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['Float']>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export type EditDonationFormTranslationsInput = {
  fields: TranslationsDonationFormFieldInput;
  translations: TranslationsDonationFormFieldInput;
};

export type EditLinkedTicketingInput = {
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates: Array<EditRateInput>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
};

export type EditPasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type EditRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditRateInput = {
  allowAutomaticRenewal?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields: Array<EditRateFieldInput>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type EditReminderDataInput = {
  id: Scalars['String'];
  reminderBody: Scalars['String'];
  reminderSubject: Scalars['String'];
};

export type EditSessionOnSubmission = {
  durationOnPage: Scalars['Float'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EditTagInput = {
  tagId: Scalars['String'];
  value: Scalars['String'];
};

export type EditTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<EditDiscountInput>>;
  discountsToDelete?: InputMaybe<Array<Scalars['String']>>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation?: InputMaybe<Scalars['Boolean']>;
  generateExtraDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  generateQrCode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder?: InputMaybe<Array<QuestionInput>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates?: InputMaybe<Array<EditRateInput>>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  thermometerTarget?: InputMaybe<Scalars['Float']>;
  ticketingFields?: InputMaybe<Array<EditTicketingFieldInput>>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerPdf?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export enum EmailContentFlow {
  Builder = 'Builder',
  Custom = 'Custom',
  Quick = 'Quick'
}

export type EmailContentObject = {
  __typename?: 'EmailContentObject';
  attachmentUrl?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  subject: Scalars['String'];
};

export type EmailDeliveryObject = {
  __typename?: 'EmailDeliveryObject';
  body: Scalars['String'];
  clickedRatio: Scalars['Float'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<EmailContentObject>;
  emailContentId?: Maybe<Scalars['String']>;
  emailsCount: Scalars['Float'];
  id: Scalars['String'];
  isPrefill: Scalars['Boolean'];
  locale: Locales;
  openedRatio: Scalars['Float'];
  organization: OrganizationObject;
  recipientEmailStatus?: Maybe<EmailStatus>;
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  recipients?: Maybe<Array<RecipientObject>>;
  scheduledFor?: Maybe<Scalars['Date']>;
  sender: UserObject;
  sentAt?: Maybe<Scalars['Date']>;
  status: EmailDeliveryStatus;
  subject: Scalars['String'];
};

export type EmailDeliveryObjectfHxcFbkO = {
  __typename?: 'EmailDeliveryObjectfHXCFbkO';
  error?: Maybe<EmailDeliveryObjectfHxcFbkOErrorObject>;
  object?: Maybe<EmailDeliveryObject>;
};

export type EmailDeliveryObjectfHxcFbkOErrorObject = {
  __typename?: 'EmailDeliveryObjectfHXCFbkOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailDeliveryResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailDeliveryStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type EmailDeliveryTableListResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailStatus {
  AwaitingProcessing = 'AwaitingProcessing',
  Bounced = 'Bounced',
  Clicked = 'Clicked',
  Delivered = 'Delivered',
  Invalid = 'Invalid',
  Opened = 'Opened',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum EmailTemplateErrorCode {
  NotFound = 'NotFound'
}

export type EmailTemplateObject = {
  __typename?: 'EmailTemplateObject';
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  subject: Scalars['String'];
  title: Scalars['String'];
};

export type EmailTemplateResponseListObject = {
  __typename?: 'EmailTemplateResponseListObject';
  error?: Maybe<EmailTemplateResponseListObjectErrorObject>;
  items?: Maybe<Array<EmailTemplateObject>>;
};

export type EmailTemplateResponseListObjectErrorObject = {
  __typename?: 'EmailTemplateResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailTemplateResponseObject = {
  __typename?: 'EmailTemplateResponseObject';
  error?: Maybe<EmailTemplateResponseObjectErrorObject>;
  object?: Maybe<EmailTemplateObject>;
};

export type EmailTemplateResponseObjectErrorObject = {
  __typename?: 'EmailTemplateResponseObjectErrorObject';
  code?: Maybe<EmailTemplateErrorCode>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailType {
  VisitorDailyEmail = 'VisitorDailyEmail',
  VisitorMonthlyEmail = 'VisitorMonthlyEmail',
  VisitorWeeklyEmail = 'VisitorWeeklyEmail'
}

export type EmailsCountByStatusObject = {
  __typename?: 'EmailsCountByStatusObject';
  count: Scalars['Float'];
  status: Scalars['String'];
};

export type EmailsCountByStatusTableListResponseObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObject';
  error?: Maybe<EmailsCountByStatusTableListResponseObjectErrorObject>;
  filteredCount: Scalars['Float'];
  hasMore: Scalars['Boolean'];
  items?: Maybe<Array<EmailsCountByStatusObject>>;
  totalCount: Scalars['Float'];
};

export type EmailsCountByStatusTableListResponseObjectErrorObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EncryptedPaymentIntentObject = {
  __typename?: 'EncryptedPaymentIntentObject';
  clientSecret: Scalars['String'];
  encryptedPaymentIntentId: Scalars['String'];
};

export type EncryptedPaymentIntentResponseObjectErrorObject = {
  __typename?: 'EncryptedPaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EventDateRange = {
  __typename?: 'EventDateRange';
  endDateUtc: Scalars['Date'];
  startDateUtc: Scalars['Date'];
};

export enum ExpectedAmountSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export type ExpiredEarlyBirdErrorObject = {
  __typename?: 'ExpiredEarlyBirdErrorObject';
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type ExportObject = {
  __typename?: 'ExportObject';
  export?: Maybe<Scalars['String']>;
  isExportSentByEmail: Scalars['Boolean'];
};

export type ExportObjectWjPq4p67 = {
  __typename?: 'ExportObjectWjPq4p67';
  error?: Maybe<ExportObjectWjPq4p67ErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportObjectWjPq4p67ErrorObject = {
  __typename?: 'ExportObjectWjPq4p67ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExportParametersInput = {
  locale: Locales;
  timezone: Scalars['String'];
};

export type ExportResponseObject = {
  __typename?: 'ExportResponseObject';
  error?: Maybe<ExportResponseObjectErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportResponseObjectErrorObject = {
  __typename?: 'ExportResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExternalAccountInput = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type ExternalAccountObject = {
  __typename?: 'ExternalAccountObject';
  accountName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<AvailablePaymentCurrency>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};

export type ExternalAccountObjectFxc74oTh = {
  __typename?: 'ExternalAccountObjectFxc74oTh';
  error?: Maybe<ExternalAccountObjectFxc74oThErrorObject>;
  object?: Maybe<ExternalAccountObject>;
};

export type ExternalAccountObjectFxc74oThErrorObject = {
  __typename?: 'ExternalAccountObjectFxc74oThErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FeatureFlagName {
  FeatureToggleBadTipperDisclaimer = 'FeatureToggleBadTipperDisclaimer',
  IsAlgoliaForFormSearch = 'IsAlgoliaForFormSearch',
  IsAuctionRebiddingDisabled = 'IsAuctionRebiddingDisabled',
  IsBookADemoDisplayedWebflow = 'IsBookADemoDisplayedWebflow',
  IsCloudflareApiEnabled = 'IsCloudflareApiEnabled',
  IsFundraiserFormEditDisabled = 'IsFundraiserFormEditDisabled',
  IsHubspotActiveInStage = 'IsHubspotActiveInStage',
  IsNewMobileFormCreationFlow = 'IsNewMobileFormCreationFlow',
  IsPaymentByPadAllowed = 'IsPaymentByPadAllowed',
  IsZeffyUnderAttackFromCardTesting = 'IsZeffyUnderAttackFromCardTesting',
  LearnMoreOnTipMessage = 'LearnMoreOnTipMessage',
  Test = 'Test',
  TipSuggestionBigAmountsLargerThan400 = 'TipSuggestionBigAmountsLargerThan400',
  TipSuggestionBigAmountsLargerThan1000 = 'TipSuggestionBigAmountsLargerThan1000',
  TipSuggestionExperience = 'TipSuggestionExperience',
  TipSuggestionSmallAmounts = 'TipSuggestionSmallAmounts'
}

export type FeatureFlagTreatmentObject = {
  __typename?: 'FeatureFlagTreatmentObject';
  name: FeatureFlagName;
  treatment: Scalars['Boolean'];
};

export enum FeatureFlagValue {
  A = 'A',
  B = 'B',
  C = 'C',
  Control = 'Control',
  D = 'D',
  E = 'E',
  F = 'F'
}

export type FirstFormObject = {
  __typename?: 'FirstFormObject';
  formCategory: FormCategory;
  formStatus: FormStatus;
  formType: FormType;
  id: Scalars['String'];
  path: Scalars['String'];
};

export enum FormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  PeerToPeerCampaign = 'PeerToPeerCampaign',
  Shop = 'Shop'
}

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type FormInput = {
  formType: FormType;
  id: Scalars['String'];
};

export type FormObject = {
  __typename?: 'FormObject';
  fields: Array<FormField>;
  formLink: Scalars['String'];
  formType: FormType;
  id: Scalars['String'];
  volume: Scalars['Float'];
};

export enum FormStatus {
  Active = 'Active',
  Draft = 'Draft'
}

export type FormTitle = {
  __typename?: 'FormTitle';
  fields: Array<FormField>;
  id: Scalars['String'];
};

export type FormTitleListResponse = {
  __typename?: 'FormTitleListResponse';
  error?: Maybe<FormTitleListResponseErrorObject>;
  items?: Maybe<Array<FormTitle>>;
};

export type FormTitleListResponseErrorObject = {
  __typename?: 'FormTitleListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FormType {
  DonationForm = 'DonationForm',
  Ticketing = 'Ticketing'
}

export enum FrequencyFilter {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type FrontendFormObject = {
  __typename?: 'FrontendFormObject';
  auctionEndDate?: Maybe<Scalars['Date']>;
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerUrl?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  fields: Array<FormField>;
  formCategory: FormCategory;
  formType: FormType;
  generateQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPrimaryCampaign?: Maybe<Scalars['Boolean']>;
  isPrimaryTeam?: Maybe<Scalars['Boolean']>;
  isRegistration?: Maybe<Scalars['Boolean']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  rates?: Maybe<Array<TicketingDetailsRate>>;
  seats?: Maybe<Scalars['Int']>;
  status: FormStatus;
  teamId?: Maybe<Scalars['String']>;
};

export type FrontendFormObjectFormObjectResponseObjectErrorObject = {
  __typename?: 'FrontendFormObjectFormObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseListObject = {
  __typename?: 'FrontendFormObjectResponseListObject';
  error?: Maybe<FrontendFormObjectResponseListObjectErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectResponseListObjectErrorObject = {
  __typename?: 'FrontendFormObjectResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseObject = {
  __typename?: 'FrontendFormObjectResponseObject';
  error?: Maybe<FrontendFormObjectFormObjectResponseObjectErrorObject>;
  object?: Maybe<FrontendFormObject>;
};

export type FrontendFormObjectx7Nd0fU1 = {
  __typename?: 'FrontendFormObjectx7ND0fU1';
  error?: Maybe<FrontendFormObjectx7Nd0fU1ErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectx7Nd0fU1ErrorObject = {
  __typename?: 'FrontendFormObjectx7ND0fU1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FullChoiceErrorObject = {
  __typename?: 'FullChoiceErrorObject';
  id: Scalars['String'];
  remainingAnswers: Scalars['Float'];
};

export type FullRateErrorObject = {
  __typename?: 'FullRateErrorObject';
  id: Scalars['String'];
  remainingTickets: Scalars['Float'];
};

export type FundraiserDonationFormObject = {
  __typename?: 'FundraiserDonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  eventFollowupEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailContentId?: Maybe<Scalars['String']>;
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isFundraiserFormEditDisabled: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type GetBalanceTransactionDetailsObject = {
  __typename?: 'GetBalanceTransactionDetailsObject';
  id: Scalars['String'];
  sourceIssuingTransaction?: Maybe<GetBalanceTransactionIssuingTransactionObject>;
  sourcePayment?: Maybe<GetBalanceTransactionPaymentObject>;
  sourcePayout?: Maybe<GetBalanceTransactionPayoutObject>;
};

export type GetBalanceTransactionDetailsObjectYDiInx4J = {
  __typename?: 'GetBalanceTransactionDetailsObjectYDiInx4J';
  error?: Maybe<GetBalanceTransactionDetailsObjectYDiInx4JErrorObject>;
  object?: Maybe<GetBalanceTransactionDetailsObject>;
};

export type GetBalanceTransactionDetailsObjectYDiInx4JErrorObject = {
  __typename?: 'GetBalanceTransactionDetailsObjectYDiInx4JErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetBalanceTransactionIssuingTransactionObject = {
  __typename?: 'GetBalanceTransactionIssuingTransactionObject';
  authorizationCreatedAtUtc: Scalars['Date'];
  city?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  merchantName: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type GetBalanceTransactionPaymentObject = {
  __typename?: 'GetBalanceTransactionPaymentObject';
  createdAtUtc: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formId: Scalars['String'];
  formName: Scalars['String'];
  formType: FormType;
  lastName: Scalars['String'];
  paymentMethod: PaymentMethod;
};

export type GetBalanceTransactionPayoutObject = {
  __typename?: 'GetBalanceTransactionPayoutObject';
  arrivalDate: Scalars['Date'];
};

export type GetCurrentUserFrontendObject = {
  __typename?: 'GetCurrentUserFrontendObject';
  connectedAsAdministrator: Scalars['Boolean'];
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  currentOrganizationDetails?: Maybe<OrganizationDetailsObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasAnyBankPermission: Scalars['Boolean'];
  hasDismissedEmailInfobox: Scalars['Boolean'];
  hasDismissedEmailTemplatePrompt: Scalars['Boolean'];
  hasDismissedReferralPromptAtFirstPayout: Scalars['Boolean'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isBankChangeSmsMfaActivated: Scalars['Boolean'];
  isLoginSmsMfaActivated: Scalars['Boolean'];
  isMigratedOnStytch: Scalars['Boolean'];
  isOnboardedOnCheckinManager: Scalars['Boolean'];
  isTwoFactorActivated: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  organizationGroups: Array<OrganizationGroupObject>;
  phone?: Maybe<Scalars['String']>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  zeffyAdministrator: Scalars['Boolean'];
};

export type GetCurrentUserFrontendObjectXaoqBlng = {
  __typename?: 'GetCurrentUserFrontendObjectXAOQBlng';
  error?: Maybe<GetCurrentUserFrontendObjectXaoqBlngErrorObject>;
  object?: Maybe<GetCurrentUserFrontendObject>;
};

export type GetCurrentUserFrontendObjectXaoqBlngErrorObject = {
  __typename?: 'GetCurrentUserFrontendObjectXAOQBlngErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetCurrentUserObject = {
  __typename?: 'GetCurrentUserObject';
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationGroups: Array<OrganizationGroupObject>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
};

export type GetCurrentUserResponseObjectErrorObject = {
  __typename?: 'GetCurrentUserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetDonationFormsFromCampaignObject = {
  __typename?: 'GetDonationFormsFromCampaignObject';
  hasMore: Scalars['Boolean'];
  individual: Array<DonationFormFromCampaignObject>;
  team: Array<DonationFormFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectrtXt8WHt = {
  __typename?: 'GetDonationFormsFromCampaignObjectrtXt8WHt';
  error?: Maybe<GetDonationFormsFromCampaignObjectrtXt8WHtErrorObject>;
  object?: Maybe<GetDonationFormsFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectrtXt8WHtErrorObject = {
  __typename?: 'GetDonationFormsFromCampaignObjectrtXt8WHtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum GetEmailDeliveryError {
  BelongToMyOtherOrganization = 'BelongToMyOtherOrganization'
}

export type GetEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'GetEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<GetEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetEphemeralKeyInput = {
  nonce: Scalars['String'];
};

export type GetEphemeralKeyObject = {
  __typename?: 'GetEphemeralKeyObject';
  ephemeralKeySecret: Scalars['String'];
};

export type GetEphemeralKeyObjectOZzvPjVd = {
  __typename?: 'GetEphemeralKeyObjectOZzvPjVD';
  error?: Maybe<GetEphemeralKeyObjectOZzvPjVdErrorObject>;
  object?: Maybe<GetEphemeralKeyObject>;
};

export type GetEphemeralKeyObjectOZzvPjVdErrorObject = {
  __typename?: 'GetEphemeralKeyObjectOZzvPjVDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetFirstTransactionCreatedAtUtcResponseObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject';
  createdAtUtc?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjectKQzvSRmU = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjectKQzvSRmU';
  error?: Maybe<GetFirstTransactionCreatedAtUtcResponseObjectKQzvSRmUErrorObject>;
  object?: Maybe<GetFirstTransactionCreatedAtUtcResponseObject>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjectKQzvSRmUErrorObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjectKQzvSRmUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingCardObject = {
  __typename?: 'GetIssuingCardObject';
  issuingCard?: Maybe<IssuingCardObject>;
};

export type GetIssuingCardObjectyEpJvwDr = {
  __typename?: 'GetIssuingCardObjectyEpJvwDR';
  error?: Maybe<GetIssuingCardObjectyEpJvwDrErrorObject>;
  object?: Maybe<GetIssuingCardObject>;
};

export type GetIssuingCardObjectyEpJvwDrErrorObject = {
  __typename?: 'GetIssuingCardObjectyEpJvwDRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingRewardTransactionsObject = {
  __typename?: 'GetIssuingRewardTransactionsObject';
  amount: Scalars['Float'];
  disputeCount: Scalars['Float'];
  transactionCount: Scalars['Float'];
};

export type GetIssuingRewardTransactionsObjectMzt4RSot = {
  __typename?: 'GetIssuingRewardTransactionsObjectMzt4RSot';
  error?: Maybe<GetIssuingRewardTransactionsObjectMzt4RSotErrorObject>;
  object?: Maybe<GetIssuingRewardTransactionsObject>;
};

export type GetIssuingRewardTransactionsObjectMzt4RSotErrorObject = {
  __typename?: 'GetIssuingRewardTransactionsObjectMzt4RSotErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetMarkerHoverDetailsInput = {
  cursorType: CursorType;
  id: Scalars['String'];
};

export type GetMarkersInput = {
  lat: LatLngBoundariesInput;
  lng: LatLngBoundariesInput;
};

export type GetOrganizationSummaryObject = {
  __typename?: 'GetOrganizationSummaryObject';
  address: Scalars['String'];
  lastDonationForm?: Maybe<DonationFormSummaryObject>;
  lastTicketing?: Maybe<TicketingSummaryObject>;
  url?: Maybe<Scalars['String']>;
};

export type GetOrganizationSummaryResponseObject = {
  __typename?: 'GetOrganizationSummaryResponseObject';
  error?: Maybe<GetOrganizationSummaryResponseObjectErrorObject>;
  object?: Maybe<GetOrganizationSummaryObject>;
};

export type GetOrganizationSummaryResponseObjectErrorObject = {
  __typename?: 'GetOrganizationSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetReferredOrganizationInput = {
  organizationId: Scalars['String'];
};

export type GetScheduledPayoutObject = {
  __typename?: 'GetScheduledPayoutObject';
  payout?: Maybe<ScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectKrs7NqTk = {
  __typename?: 'GetScheduledPayoutObjectKrs7NqTK';
  error?: Maybe<GetScheduledPayoutObjectKrs7NqTkErrorObject>;
  object?: Maybe<GetScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectKrs7NqTkErrorObject = {
  __typename?: 'GetScheduledPayoutObjectKrs7NqTKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetTipSuggestionsInput = {
  amount: Scalars['Float'];
  tipModelExperience?: InputMaybe<TipModelExperience>;
};

export type GetTransactionsForIssuingBalanceTransferObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject';
  endDate?: Maybe<Scalars['Date']>;
  volume: Scalars['Float'];
};

export type GetTransactionsForIssuingBalanceTransferObjectUaNwLiTa = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObjectUaNwLiTA';
  error?: Maybe<GetTransactionsForIssuingBalanceTransferObjectUaNwLiTaErrorObject>;
  object?: Maybe<GetTransactionsForIssuingBalanceTransferObject>;
};

export type GetTransactionsForIssuingBalanceTransferObjectUaNwLiTaErrorObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObjectUaNwLiTAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HasEmailDeliveryObject = {
  __typename?: 'HasEmailDeliveryObject';
  draft: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  sent: Scalars['Boolean'];
};

export type HasEmailDeliveryObjectw4jMqfag = {
  __typename?: 'HasEmailDeliveryObjectw4jMQFAG';
  error?: Maybe<HasEmailDeliveryObjectw4jMqfagErrorObject>;
  object?: Maybe<HasEmailDeliveryObject>;
};

export type HasEmailDeliveryObjectw4jMqfagErrorObject = {
  __typename?: 'HasEmailDeliveryObjectw4jMQFAGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  frequency: FrequencyFilter;
  startDate: Scalars['Date'];
};

export type HomeGraphObject = {
  __typename?: 'HomeGraphObject';
  date: Scalars['Date'];
  volume: Scalars['Int'];
};

export type HomeGraphObjectResponseObjectErrorObject = {
  __typename?: 'HomeGraphObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphResponseListObject = {
  __typename?: 'HomeGraphResponseListObject';
  error?: Maybe<HomeGraphResponseListObjectErrorObject>;
  items?: Maybe<Array<HomeGraphObject>>;
};

export type HomeGraphResponseListObjectErrorObject = {
  __typename?: 'HomeGraphResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeMetricsInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type HubspotProperty = {
  propertyName: Scalars['String'];
  propertyValue: Scalars['String'];
};

export type ImportCheckErrorObject = {
  __typename?: 'ImportCheckErrorObject';
  emptyColumns: Array<Scalars['String']>;
  invalidColumns: Array<Scalars['String']>;
  row: Scalars['Float'];
};

export enum ImportStatus {
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum ImportType {
  Contact = 'Contact',
  Payment = 'Payment'
}

export type ImportUsersGoogleSheetObject = {
  __typename?: 'ImportUsersGoogleSheetObject';
  addedContactCount?: Maybe<Scalars['Float']>;
  addedPaymentCount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ImportCheckErrorObject>>;
  id?: Maybe<Scalars['String']>;
  isAsync?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ImportStatus>;
};

export type ImportUsersGoogleSheetObjectnsOokz3D = {
  __typename?: 'ImportUsersGoogleSheetObjectnsOokz3D';
  error?: Maybe<ImportUsersGoogleSheetObjectnsOokz3DErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectnsOokz3DErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectnsOokz3DErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetObjectqQ1xjHqr = {
  __typename?: 'ImportUsersGoogleSheetObjectqQ1xjHqr';
  error?: Maybe<ImportUsersGoogleSheetObjectqQ1xjHqrErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectqQ1xjHqrErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectqQ1xjHqrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetResponseObjectErrorObject = {
  __typename?: 'ImportUsersGoogleSheetResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IncreasePayoutAmountInput = {
  amount: Scalars['Float'];
  payoutId: Scalars['String'];
};

export type IncreasePayoutAmountObject = {
  __typename?: 'IncreasePayoutAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type IncreasePayoutAmountObjectKzIjIxYm = {
  __typename?: 'IncreasePayoutAmountObjectKZIjIxYM';
  error?: Maybe<IncreasePayoutAmountObjectKzIjIxYmErrorObject>;
  object?: Maybe<IncreasePayoutAmountObject>;
};

export type IncreasePayoutAmountObjectKzIjIxYmErrorObject = {
  __typename?: 'IncreasePayoutAmountObjectKZIjIxYMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type InsertTagInput = {
  tagId: Scalars['String'];
  userId: Scalars['String'];
};

export type IntResponseObject = {
  __typename?: 'IntResponseObject';
  error?: Maybe<IntegerResponseObjectErrorObject>;
  object?: Maybe<Scalars['Int']>;
};

export type IntegerResponseObjectErrorObject = {
  __typename?: 'IntegerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IsEmailRegisteredInput = {
  email: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  redirectionUrl?: InputMaybe<Scalars['String']>;
};

export type IsEmailRegisteredSsoInput = {
  googleToken: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};

export type IsUserEmailRegisteredObject = {
  __typename?: 'IsUserEmailRegisteredObject';
  fundraiserCreationToken?: Maybe<Scalars['String']>;
  hadPasswordInSimplykV1: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hasResetPasswordToken: Scalars['Boolean'];
  shouldAuthenticateWithGoogle?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userRegistrationStatus: UserRegistrationStatus;
};

export type IsUserEmailRegisteredResponseObject = {
  __typename?: 'IsUserEmailRegisteredResponseObject';
  error?: Maybe<IsUserEmailRegisteredResponseObjectErrorObject>;
  object?: Maybe<IsUserEmailRegisteredObject>;
};

export type IsUserEmailRegisteredResponseObjectErrorObject = {
  __typename?: 'IsUserEmailRegisteredResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IssuingCardObject = {
  __typename?: 'IssuingCardObject';
  cardHolderStatus: CardHolderStatus;
  id: Scalars['String'];
  isCardHolder: Scalars['Boolean'];
  last4?: Maybe<Scalars['String']>;
  status: IssuingCardStatus;
  stripeIssuingCardId?: Maybe<Scalars['String']>;
  treasuryFinancialAccountId?: Maybe<Scalars['String']>;
};

export enum IssuingCardStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type LatLngBoundariesInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type ListTransactionTicketsObject = {
  __typename?: 'ListTransactionTicketsObject';
  allCanceled: Scalars['Boolean'];
  productTickets: Array<ProductTicketObject>;
};

export type ListTransactionTicketsObjectErrorObject = {
  __typename?: 'ListTransactionTicketsObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ListTransactionTicketsResponse = {
  __typename?: 'ListTransactionTicketsResponse';
  error?: Maybe<ListTransactionTicketsObjectErrorObject>;
  object?: Maybe<ListTransactionTicketsObject>;
};

export enum Locales {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type ManualContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  note?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MarkerHoverDetailsObject = {
  __typename?: 'MarkerHoverDetailsObject';
  color?: Maybe<Scalars['String']>;
  cursorType: CursorType;
  dates: Array<EventDateRange>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MarkerPositionObject = {
  __typename?: 'MarkerPositionObject';
  coordinates: CoordinatesObject;
  cursorType: CursorType;
  id: Scalars['String'];
};

export type MarkerPositionResponseObjectErrorObject = {
  __typename?: 'MarkerPositionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum MembershipValidityPeriod {
  AtDate = 'AtDate',
  NoExpiration = 'NoExpiration',
  OneMonthAfterPayment = 'OneMonthAfterPayment',
  OneYearAfterPayment = 'OneYearAfterPayment'
}

export enum MergeUsersError {
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  UserNotFound = 'UserNotFound'
}

export type MergeUsersObject = {
  __typename?: 'MergeUsersObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type MergeUsersObjectJq5LveHg = {
  __typename?: 'MergeUsersObjectJq5LVEHg';
  error?: Maybe<MergeUsersObjectJq5LveHgErrorObject>;
  object?: Maybe<MergeUsersObject>;
};

export type MergeUsersObjectJq5LveHgErrorObject = {
  __typename?: 'MergeUsersObjectJq5LVEHgErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectLabelObject = {
  __typename?: 'MultiselectLabelObject';
  label: Scalars['String'];
  locale: Locales;
};

export type MultiselectOccurrenceValueObject = {
  __typename?: 'MultiselectOccurrenceValueObject';
  endUtc: Scalars['Date'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  value: Scalars['String'];
};

export type MultiselectOccurrenceValueObjectungfSp3c = {
  __typename?: 'MultiselectOccurrenceValueObjectungfSp3c';
  error?: Maybe<MultiselectOccurrenceValueObjectungfSp3cErrorObject>;
  items?: Maybe<Array<MultiselectOccurrenceValueObject>>;
};

export type MultiselectOccurrenceValueObjectungfSp3cErrorObject = {
  __typename?: 'MultiselectOccurrenceValueObjectungfSp3cErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObject = {
  __typename?: 'MultiselectValueObject';
  labels: Array<MultiselectLabelObject>;
  value: Scalars['String'];
};

export type MultiselectValueObject4a6oxS0o = {
  __typename?: 'MultiselectValueObject4a6oxS0o';
  error?: Maybe<MultiselectValueObject4a6oxS0oErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObject4a6oxS0oErrorObject = {
  __typename?: 'MultiselectValueObject4a6oxS0oErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObjectltiNoL9e = {
  __typename?: 'MultiselectValueObjectltiNoL9e';
  error?: Maybe<MultiselectValueObjectltiNoL9eErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectltiNoL9eErrorObject = {
  __typename?: 'MultiselectValueObjectltiNoL9eErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueResponseListObject = {
  __typename?: 'MultiselectValueResponseListObject';
  error?: Maybe<MultiselectValueResponseListObjectErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueResponseListObjectErrorObject = {
  __typename?: 'MultiselectValueResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCardIssuing: Booleanyrbv5Npp;
  activateDonationForm: DonationFormObjectcQcnekNl;
  activateTicketing: TicketingObjectoAddkpUr;
  activeTwoFactorAuth: Boolean4T03Kfnc;
  addEngagementStepChecked: BooleantOjx1czI;
  addManualContact: StringcizTzBnM;
  addRetroActiveReferral: BooleanxqpuaBjH;
  addTicketingOffset: Boolean5tIbOWui;
  archiveCampaign: NumberXzsKz0Jb;
  archiveDonationForm: Boolean5ACp3cMc;
  archiveTeam: BooleanResiJSrg;
  archiveTicketing: Booleanl3aWyxgp;
  cancelCommandSubscription: CommandObjectMMzqMyFs;
  cancelDonorCommandSubscription: CommandObjectJzc26wLq;
  cancelReceipt: ReceiptObjectJvLjnbq3;
  capturePaymentIntent: BooleanResponseObject;
  changeContactEmailSubscription: BooleanRxOrOsGe;
  checkinProductTicket: BooleannJlfMgBd;
  checkinProductTicketByCommandId: BooleanQIdGEdCq;
  closeTicketing: BooleanFhd9cmyq;
  confirmPaymentIntent: CommandObjectv9R8S6Lz;
  createBalanceTransferToIssuing: BooleanunssNo98;
  createDonationFormFromTemplate: StringZtN0cCxz;
  createDonorFormTranslations: DonationFormObjectKMziD9Br;
  createDraftCampaign: DonationFormObjectoL5n8Atm;
  createDraftDonationForm: DonationFormObjectJv0gc9qD;
  createDraftTicketing: String3aj7Ey9k;
  createFormTranslations: DonationFormObjectv7lcFFtk;
  createFrontendDonor: UserResponseObject;
  createFundraiserFormTranslations: DonationFormObjectLhW1rvRa;
  createIssuingPayout: CreatedIssuingPayoutObject6wIgd7oi;
  createIssuingTopup: BooleanZm8tKehi;
  createNewOrganization: AccessTokenObjectcIeIIjFq;
  createNote: NoteResponseObject;
  createPlaidLinkToken: StringrdkSbr1N;
  createPreviewReceipt: StringBhLiiTze;
  createReceipt: ReceiptResponseObject;
  createSampleContact: ContactObject2k5aFgTo;
  createSampleTransaction: TransactionObject0Gu6eFcx;
  createStripeCheckoutSession: CheckoutSessionResponseObject;
  createStripeCustomExternalAccount: ExternalAccountObjectFxc74oTh;
  createTags: StringListResponseObject;
  createTicketingCardPaymentIntent: PaymentIntentResponseObject;
  deactivateOrganization: BooleanResponseObject;
  deactiveTwoFactorAuth: BooleanVKzC3Q9R;
  deleteCampaign: Number6urlbjsD;
  deleteContact: BooleanResponseObject;
  deleteDonationForm: Booleanqc61ytTb;
  deleteEmailTemplate: EmailTemplateResponseObject;
  deleteMemberToOrganizationAdministrators: UserObjectncWqS4zQ;
  deleteNote?: Maybe<StringResponseObject>;
  deleteSampleContact: BooleanZsFqCwua;
  deleteSampleTransaction: BooleanwPWrPlAw;
  deleteTagForAllUsers: BooleanResponseObject;
  deleteTags: BooleanResponseObject;
  deleteTeam: Boolean3KdRnHj4;
  deleteTicketing: BooleanPuPflp2c;
  deleteUser: BooleanF8vhXs2c;
  disableGoogleSsoLogin: Booleangl19elyk;
  dismissActivationPayoutSuccessCard: Boolean0AfgM1Bb;
  displayEngagementChecklist: Booleany9Z2hguA;
  editAnswer: AnswerResponseObject;
  editCommandSubscriptionAmount: CommandResponseObject;
  editContact: ContactResponseObject;
  editContactEmail: UserObjectLyEe4aXp;
  editDonationCommandForm: CommandResponseObject;
  editDonorCommandSubscriptionAmount: CommandResponseObject;
  editDonorFormAdvancedParameters: DonationFormObject92Mfevvr;
  editDonorFormCustomization: DonationFormObjectp21HYhoU;
  editDonorFormGeneralInformations: DonationFormObject5bdAxsBj;
  editFormAdvancedParameters: DonationFormObjectvMuwu4Cl;
  editFormCustomization: DonationFormObjectIzszSvra;
  editFormGeneralInformations: DonationFormObjectDc0Wx3Db;
  editFundraiserFormAdvancedParameters: DonationFormObjectExp213cr;
  editFundraiserFormCustomization: DonationFormObject3ymcGqbK;
  editFundraiserFormGeneralInformations: DonationFormObjectTnGocCRr;
  editNote?: Maybe<NoteResponseObject>;
  editPassword: UserObjectFqG1UuqT;
  editTag: BooleanResponseObject;
  editTicketingAdvancedParameters: TicketingObjectzyGQj2aY;
  editTicketingEventCustomization: TicketingObjectVyzMqZmo;
  editTicketingGeneralInformation: TicketingObjectq0pJzxG4;
  editTicketingRates: TicketingObjectMYwZ960J;
  editTicketingReminderData: TicketingObjectDBxIjb8p;
  editUnsubscribePreferences: BooleanAq5ZdSax;
  exportBalanceTransactionsTable: ExportResponseObject;
  exportDonorReceiptTable: ExportResponseObject;
  exportFundraiserList: ExportObjectWjPq4p67;
  exportPayoutsTable: ExportResponseObject;
  exportTransactionsTable: ExportResponseObject;
  generatePreviewETicket: StringResponseObject;
  getCannyToken: StringResponseObject;
  getDiscountCodeFromTicketingIdAndBody: DiscountResponseObject;
  increasePayoutAmount: IncreasePayoutAmountObjectKzIjIxYm;
  insertTags: BooleanResponseObject;
  isUserEmailRegistered: IsUserEmailRegisteredResponseObject;
  isUserEmailRegisteredSSO: AccessTokenObjects9jO4yds;
  launchImport: ImportUsersGoogleSheetObjectnsOokz3D;
  mergeTeams: BooleanZ3i3zuFq;
  mergeUsers: MergeUsersObjectJq5LveHg;
  moveParticipantToCampaign: BooleanVWfbzzdK;
  openTicketing: TicketingObject7ukBtN2n;
  postSubmitCommand: PostSubmitCommandObjectrYjXVo7U;
  processIssuingRewards: BooleanBt8U6dpU;
  processPayout: BooleanseHk8chb;
  purgeAllCache: BooleanlXfVcTc5;
  reOpenCommand: BooleaniHxSkK7g;
  refundTip: TransactionObjectxqL3u02s;
  refundTransaction: RefundResponseObject;
  removeEngagementChecklist: Booleanu1OoIcmA;
  requestIssuingCard: BooleanzMr2ftAs;
  resendTransactionReceipt?: Maybe<StringResponseObject>;
  resendTwoFactorCode: BooleanesbWka3y;
  resetEngagementStepChecked: BooleanMnWi5BqN;
  resetPassword: AccessTokenObject5eXnj20X;
  reverseDonationAnonymous: BooleanV883OiId;
  saveCardOrder: CreateOrderResponseObject;
  saveDonationThankYouComment: CommandResponseObject;
  saveV1MobileCommand: CreateMobileCommandResponseObject;
  scanTicket: ProductTicketObjectwvIrya5S;
  sendMergeUserRequest: SendMergeUsersRequestResponseObject;
  sendMonthlyBalanceTransactionExport: BooleanKMp2yLw9;
  sendResetPasswordRequest: ResetPasswordLinkResponseObject;
  sendResetPasswordRequestMobile: ResetPasswordLinkResponseObject;
  sendTransactionReceiptForExistingCommand: CommandResponseObject;
  sendTwoFactorCode: BooleankYbme3Qc;
  sendVerifyEmailNotification: BooleanMnb2gcUe;
  setHasDismissedEmailInfobox: BooleanBKlmiVgo;
  setHasDismissedEmailTemplatePrompt: BooleanOBaIm17B;
  setHasDismissedReferralPrompt: BooleanE8d6oLy1;
  setHasSeenReferralPage: BooleanResponseObject;
  setUserAsOnboardedOnCheckinManager: BooleanPIb9QfrC;
  setUserCurrentOrganization: AccessTokenObjectSAljh70I;
  setUserCurrentOrganizationMobile: AccessTokenObject;
  setVolumeThresholdReached: OrganizationResponseObject;
  signIn: AccessTokenObjectD67Mi8Fe;
  signInAsAdministrator: AccessTokenObjectjZmTiq3Z;
  signInMobile?: Maybe<AccessTokenObject>;
  signInMobileWithGoogle?: Maybe<AccessTokenObject>;
  signOut: BooleanzPn6SqRf;
  signUp: AccessTokenObject9H4e4x5K;
  switchOrgAsAdministrator: AccessTokenResponseObject;
  testModeAutomateOnboardingToStripe: BooleanResponseObject;
  trackDownload?: Maybe<Scalars['Boolean']>;
  triggerManualCron: BooleanUfkVc8qP;
  unarchiveCampaign: NumberWPuzCfhr;
  unarchiveDonationForm: BooleanpoBiCoAn;
  unarchiveTicketing: BooleanWtCgkzoo;
  unsubscribe: ContactResponseObject;
  unsubscribeUserFromEmails: BooleanKwJdbid5;
  updateAccessTokenWithTargetedOrganization: Booleanxao1YXp6;
  updateAdministrativeInformation: OrganizationObjectRyNeXqbs;
  updateAdministrativeMoreInformation: OrganizationObjectrvAByN9e;
  updateAmountsFromToken: String9Ev8S10B;
  updateCompanyOnboardingQualification: BooleanResponseObject;
  updateHubspotContactProperty: Boolean4l5oHfzl;
  updateOrganizationCategory: BooleanResponseObject;
  updateOrganizationEngagementFlags: BooleanResponseObject;
  updateOrganizationFlags: BooleanKehGmdAk;
  updateOrganizationHubspotProperty: BooleanResponseObject;
  updateOrganizationOnboardedFormCategory: BooleanFkWcmGwq;
  updateOrganizationWithStripeCustomFlowHasStarted: BooleanpfhGTw78;
  updatePaymentIntent: UpdatePaymentIntentResponse;
  updatePayoutInterval: StripeAccountResponseObject;
  updateProductDonationAnnotation: BooleanemSGtzTj;
  updateProductDonationAnonymous: BooleanpixDKwE7;
  updateProductTicketAnnotation: BooleanResponseObject;
  uploadChunkForImport: ChunkImportObjectVTi1ANkn;
  upsertSession: SessionResponseObject;
  verifyEmail: VerifyEmailObjectcWt0Tbs7;
  verifyTwoFactorCode: BooleanaKmQa4Dg;
};


export type MutationActivateDonationFormArgs = {
  activateDonationFormInput: ActivateDonationFormInput;
};


export type MutationActivateTicketingArgs = {
  activateTicketingInput: ActivateTicketingInput;
};


export type MutationAddEngagementStepCheckedArgs = {
  activationStepId: Scalars['String'];
};


export type MutationAddManualContactArgs = {
  manualContactInput: ManualContactInput;
};


export type MutationAddRetroActiveReferralArgs = {
  retroActiveReferralInput: RetroActiveReferralInput;
};


export type MutationAddTicketingOffsetArgs = {
  offset: Scalars['Int'];
  ticketingIdOrPath: Scalars['String'];
};


export type MutationArchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationArchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationArchiveTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationArchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationCancelCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelDonorCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  receiptId: Scalars['String'];
};


export type MutationCapturePaymentIntentArgs = {
  paymentIntentId: Scalars['String'];
};


export type MutationChangeContactEmailSubscriptionArgs = {
  unsubscribe: Scalars['Boolean'];
  userEmails: Array<Scalars['String']>;
};


export type MutationCheckinProductTicketArgs = {
  isCheckin: Scalars['Boolean'];
  productTicketId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckinProductTicketByCommandIdArgs = {
  commandId: Scalars['String'];
  isCheckin: Scalars['Boolean'];
  token: Scalars['String'];
};


export type MutationCloseTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationConfirmPaymentIntentArgs = {
  confirmPaymentIntentInput: ConfirmPaymentIntentInput;
};


export type MutationCreateBalanceTransferToIssuingArgs = {
  input: ProcessBalanceTransferInput;
};


export type MutationCreateDonationFormFromTemplateArgs = {
  template: DonationFormTemplateType;
};


export type MutationCreateDonorFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateDraftCampaignArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftDonationFormArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftTicketingArgs = {
  ticketing: CreateTicketingInput;
};


export type MutationCreateFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateFrontendDonorArgs = {
  donor: DonorInput;
};


export type MutationCreateFundraiserFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
  token: Scalars['String'];
};


export type MutationCreateIssuingPayoutArgs = {
  input: CreatedIssuingPayoutInput;
};


export type MutationCreateIssuingTopupArgs = {
  input: CreateIssuingTopupInput;
};


export type MutationCreateNewOrganizationArgs = {
  newOrganizationInput: CreateNewOrganizationInput;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreatePreviewReceiptArgs = {
  previewReceiptParams: PreviewReceiptParams;
};


export type MutationCreateReceiptArgs = {
  receipt: CreateReceiptInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  customerId: Scalars['String'];
  subscriptionId: Scalars['String'];
};


export type MutationCreateStripeCustomExternalAccountArgs = {
  externalAccountInput: ExternalAccountInput;
};


export type MutationCreateTagsArgs = {
  tag: CreateTagInput;
};


export type MutationCreateTicketingCardPaymentIntentArgs = {
  paymentIntent: TicketingPaymentIntentInput;
};


export type MutationDeactivateOrganizationArgs = {
  input: DeactivateAccountInput;
};


export type MutationDeactiveTwoFactorAuthArgs = {
  param: DeactiveTwoFactorAuthInput;
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteDonationFormArgs = {
  donationFormId: Scalars['String'];
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberToOrganizationAdministratorsArgs = {
  email: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationDeleteSampleContactArgs = {
  contactId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSampleTransactionArgs = {
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTagForAllUsersArgs = {
  tagId: Scalars['String'];
};


export type MutationDeleteTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationDeleteTicketingArgs = {
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
  ticketingId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserAccountInput;
};


export type MutationDisableGoogleSsoLoginArgs = {
  userEmail: Scalars['String'];
};


export type MutationEditAnswerArgs = {
  answerInput: AnswerInput;
};


export type MutationEditCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditContactArgs = {
  editContactInput: EditContactInput;
  userId: Scalars['String'];
};


export type MutationEditContactEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEditDonationCommandFormArgs = {
  donationFormId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationEditDonorCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditDonorFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFundraiserFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditNoteArgs = {
  content: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationEditPasswordArgs = {
  editPasswordInput: EditPasswordInput;
};


export type MutationEditTagArgs = {
  tag: EditTagInput;
};


export type MutationEditTicketingAdvancedParametersArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingEventCustomizationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingGeneralInformationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingRatesArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingReminderDataArgs = {
  data: EditReminderDataInput;
};


export type MutationEditUnsubscribePreferencesArgs = {
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
};


export type MutationExportBalanceTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<BalanceTransactionExportFilter>;
};


export type MutationExportDonorReceiptTableArgs = {
  exportParameters: ExportParametersInput;
  year: Scalars['Float'];
};


export type MutationExportFundraiserListArgs = {
  donationFormId: Scalars['String'];
  exportParameters: ExportParametersInput;
};


export type MutationExportPayoutsTableArgs = {
  exportParameters: ExportParametersInput;
};


export type MutationExportTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<TransactionExportFilter>;
};


export type MutationGeneratePreviewETicketArgs = {
  ticketPreviewParams: PreviewTicketParams;
};


export type MutationGetDiscountCodeFromTicketingIdAndBodyArgs = {
  discountCodeBody: Scalars['String'];
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type MutationIncreasePayoutAmountArgs = {
  input: IncreasePayoutAmountInput;
};


export type MutationInsertTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationIsUserEmailRegisteredArgs = {
  isEmailRegisteredInput: IsEmailRegisteredInput;
};


export type MutationIsUserEmailRegisteredSsoArgs = {
  isEmailRegisteredSSOInput: IsEmailRegisteredSsoInput;
};


export type MutationLaunchImportArgs = {
  base64: Scalars['String'];
  rowNum: Scalars['Float'];
  type: ImportType;
};


export type MutationMergeTeamsArgs = {
  teamToBeMergedIdOrPath: Scalars['String'];
  teamToMergeInIdOrPath: Scalars['String'];
};


export type MutationMergeUsersArgs = {
  encryptedToken: Scalars['String'];
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationMoveParticipantToCampaignArgs = {
  participantIdOrPath: Scalars['String'];
  targetedTeamIdOrPath: Scalars['String'];
};


export type MutationOpenTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationPostSubmitCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationProcessPayoutArgs = {
  payoutId: Scalars['String'];
};


export type MutationReOpenCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationRefundTipArgs = {
  amountToRefund: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationRefundTransactionArgs = {
  amountToRefund: Scalars['Float'];
  cancelTaxReceipt: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationRequestIssuingCardArgs = {
  cardHolderInput: CreateCardHolderInput;
};


export type MutationResendTransactionReceiptArgs = {
  email: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationResendTwoFactorCodeArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationReverseDonationAnonymousArgs = {
  donationFormIdOrPath: Scalars['String'];
  donorEmail: Scalars['String'];
};


export type MutationSaveCardOrderArgs = {
  editSessionInput: EditSessionOnSubmission;
  order: CreateOrderInput;
  ticketingId: Scalars['String'];
  tickets: Array<CreateTicketInput>;
};


export type MutationSaveDonationThankYouCommentArgs = {
  commandId: Scalars['String'];
  thankYouComment: Scalars['String'];
};


export type MutationSaveV1MobileCommandArgs = {
  createMobileCommandInput: CreateMobileCommandInput;
};


export type MutationScanTicketArgs = {
  ticketIdInput: UuidInput;
};


export type MutationSendMergeUserRequestArgs = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationSendResetPasswordRequestArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendResetPasswordRequestMobileArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendTransactionReceiptForExistingCommandArgs = {
  commandId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendVerifyEmailNotificationArgs = {
  redirectionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationSetUserCurrentOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetUserCurrentOrganizationMobileArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetVolumeThresholdReachedArgs = {
  volumeThresholdReachedInput: UpdateVolumeThresholdReached;
};


export type MutationSignInArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationSignInAsAdministratorArgs = {
  signInAsAdministratorInput: SignInAsAdministratorInput;
};


export type MutationSignInMobileArgs = {
  signInInput: SignInInput;
};


export type MutationSignInMobileWithGoogleArgs = {
  googleToken: Scalars['String'];
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationSwitchOrgAsAdministratorArgs = {
  switchOrgAsAdministratorInput: SwitchOrgAsAdministratorInput;
};


export type MutationTrackDownloadArgs = {
  location: Scalars['String'];
};


export type MutationTriggerManualCronArgs = {
  cronName: CronQueues;
  delay?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUnarchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationUnarchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationUnarchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationUnsubscribeArgs = {
  contactId: Scalars['String'];
  emailDeliveryId: Scalars['String'];
};


export type MutationUnsubscribeUserFromEmailsArgs = {
  organizationId: Scalars['String'];
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
  userId: Scalars['String'];
};


export type MutationUpdateAccessTokenWithTargetedOrganizationArgs = {
  targetOrganizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdministrativeInformationArgs = {
  updateAdministrativeInformationInput: UpdateAdministrativeInformationInput;
};


export type MutationUpdateAdministrativeMoreInformationArgs = {
  updateAdministrativeMoreInformationInput: UpdateAdministrativeMoreInformationInput;
};


export type MutationUpdateAmountsFromTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateCompanyOnboardingQualificationArgs = {
  qualificationInput: OnboardingQualificationInput;
};


export type MutationUpdateHubspotContactPropertyArgs = {
  payload: HubspotProperty;
};


export type MutationUpdateOrganizationCategoryArgs = {
  category: OrganizationCategory;
};


export type MutationUpdateOrganizationEngagementFlagsArgs = {
  updateOrganizationEngagementFlagsInput: UpdateOrganizationEngagementFlagsInput;
};


export type MutationUpdateOrganizationFlagsArgs = {
  input: OrganizationFlagsInput;
};


export type MutationUpdateOrganizationHubspotPropertyArgs = {
  input: UpdateOrganizationHubspotPropertyInput;
};


export type MutationUpdateOrganizationOnboardedFormCategoryArgs = {
  onboardedFormCategory: FormCategory;
};


export type MutationUpdatePaymentIntentArgs = {
  params: UpdatePaymentIntentInput;
};


export type MutationUpdatePayoutIntervalArgs = {
  updatePayoutInterval: UpdatePayoutInterval;
};


export type MutationUpdateProductDonationAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productDonationId: Scalars['String'];
};


export type MutationUpdateProductDonationAnonymousArgs = {
  productDonationId: Scalars['String'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateProductTicketAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productTicketId: Scalars['String'];
};


export type MutationUploadChunkForImportArgs = {
  chunk: Scalars['String'];
  chunkIndex: Scalars['Float'];
  importId?: InputMaybe<Scalars['String']>;
  isLastChunk: Scalars['Boolean'];
  type: ImportType;
};


export type MutationUpsertSessionArgs = {
  sessionInput: UpsertSessionInput;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};


export type MutationVerifyTwoFactorCodeArgs = {
  value: Scalars['String'];
};

export type MyReferralOrganizationObject = {
  __typename?: 'MyReferralOrganizationObject';
  count: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['String'];
  isStripeCustomAccountActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MyReferralOrganizationObjectycB3jbou = {
  __typename?: 'MyReferralOrganizationObjectycB3jbou';
  error?: Maybe<MyReferralOrganizationObjectycB3jbouErrorObject>;
  items?: Maybe<Array<MyReferralOrganizationObject>>;
};

export type MyReferralOrganizationObjectycB3jbouErrorObject = {
  __typename?: 'MyReferralOrganizationObjectycB3jbouErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MyReferrerOrganizationObject = {
  __typename?: 'MyReferrerOrganizationObject';
  moneyGathered: Scalars['Float'];
  organization: OrganizationObject;
};

export type MyReferrerOrganizationResponseObject = {
  __typename?: 'MyReferrerOrganizationResponseObject';
  error?: Maybe<MyReferrerOrganizationResponseObjectErrorObject>;
  object?: Maybe<MyReferrerOrganizationObject>;
};

export type MyReferrerOrganizationResponseObjectErrorObject = {
  __typename?: 'MyReferrerOrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NewNonprofitObject = {
  __typename?: 'NewNonprofitObject';
  city?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  einBn: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NewNonprofitObjectDmvCwOdP = {
  __typename?: 'NewNonprofitObjectDmvCwOdP';
  error?: Maybe<NewNonprofitObjectDmvCwOdPErrorObject>;
  items?: Maybe<Array<NewNonprofitObject>>;
};

export type NewNonprofitObjectDmvCwOdPErrorObject = {
  __typename?: 'NewNonprofitObjectDmvCwOdPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum NewSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Created = 'Created',
  PastDue = 'PastDue'
}

export type NextActionObject = {
  __typename?: 'NextActionObject';
  paymentIntentSecret?: Maybe<Scalars['String']>;
  redirectToUrl?: Maybe<RedirectToUrlObject>;
  type?: Maybe<NextActionType>;
};

export enum NextActionType {
  OpenAchModal = 'OpenAchModal',
  OpenPadModal = 'OpenPadModal',
  ThreeDSecureRedirect = 'ThreeDSecureRedirect'
}

export type NoteObject = {
  __typename?: 'NoteObject';
  contactId: Scalars['String'];
  content: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  writer?: Maybe<UserObject>;
  writerId: Scalars['String'];
};

export type NoteResponseListObject = {
  __typename?: 'NoteResponseListObject';
  error?: Maybe<NoteResponseListObjectErrorObject>;
  items?: Maybe<Array<NoteObject>>;
};

export type NoteResponseListObjectErrorObject = {
  __typename?: 'NoteResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NoteResponseObject = {
  __typename?: 'NoteResponseObject';
  error?: Maybe<NoteResponseObjectErrorObject>;
  object?: Maybe<NoteObject>;
};

export type NoteResponseObjectErrorObject = {
  __typename?: 'NoteResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number6urlbjsD = {
  __typename?: 'Number6urlbjsD';
  error?: Maybe<Number6urlbjsDErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number6urlbjsDErrorObject = {
  __typename?: 'Number6urlbjsDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberEMpOq5Bd = {
  __typename?: 'NumberEMpOQ5BD';
  error?: Maybe<NumberEMpOq5BdErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberEMpOq5BdErrorObject = {
  __typename?: 'NumberEMpOQ5BDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberIyxglVqP = {
  __typename?: 'NumberIyxglVqP';
  error?: Maybe<NumberIyxglVqPErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberIyxglVqPErrorObject = {
  __typename?: 'NumberIyxglVqPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObject = {
  __typename?: 'NumberResponseObject';
  error?: Maybe<NumberResponseObjectErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberResponseObjectErrorObject = {
  __typename?: 'NumberResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObjectnt1dMfMj = {
  __typename?: 'NumberResponseObjectnt1dMFMj';
  error?: Maybe<NumberResponseObjectnt1dMfMjErrorObject>;
  object?: Maybe<NumberResponseObject>;
};

export type NumberResponseObjectnt1dMfMjErrorObject = {
  __typename?: 'NumberResponseObjectnt1dMFMjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberWPuzCfhr = {
  __typename?: 'NumberWPuzCfhr';
  error?: Maybe<NumberWPuzCfhrErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberWPuzCfhrErrorObject = {
  __typename?: 'NumberWPuzCfhrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberXzsKz0Jb = {
  __typename?: 'NumberXzsKZ0Jb';
  error?: Maybe<NumberXzsKz0JbErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberXzsKz0JbErrorObject = {
  __typename?: 'NumberXzsKZ0JbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesListResponseObjectErrorObject = {
  __typename?: 'OccurrenceWithRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesObject = {
  __typename?: 'OccurrenceWithRatesObject';
  occurrence?: Maybe<TicketingOccurrenceObject>;
  rates: Array<RateByOccurrenceObject>;
};

export enum OnboardingQualification {
  Large = 'Large',
  Medium = 'Medium',
  Other = 'Other',
  Small = 'Small'
}

export type OnboardingQualificationInput = {
  onboardingQualification: OnboardingQualification;
};

export type OrderObject = {
  __typename?: 'OrderObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  createdCampaigns?: Maybe<CreatedCampaignsObject>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<DiscountObject>;
  discountId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraDonation?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastTransactionId?: Maybe<Scalars['String']>;
  locale: Locales;
  occurrence?: Maybe<TicketingOccurrenceObject>;
  occurrenceId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  ticketing: TicketingObject;
  ticketingId: Scalars['String'];
  tickets?: Maybe<Array<TicketObject>>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderResponseObjectErrorObject = {
  __typename?: 'OrderResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationAndOwnerObject = {
  __typename?: 'OrganizationAndOwnerObject';
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  role: Scalars['String'];
  userId: Scalars['String'];
};

export type OrganizationAndOwnerObjectFfOyiHbm = {
  __typename?: 'OrganizationAndOwnerObjectFfOyiHBM';
  error?: Maybe<OrganizationAndOwnerObjectFfOyiHbmErrorObject>;
  items?: Maybe<Array<OrganizationAndOwnerObject>>;
};

export type OrganizationAndOwnerObjectFfOyiHbmErrorObject = {
  __typename?: 'OrganizationAndOwnerObjectFfOyiHBMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationCategory {
  AnimalWelfare = 'AnimalWelfare',
  CommunityService = 'CommunityService',
  Culture = 'Culture',
  Education = 'Education',
  Environment = 'Environment',
  Health = 'Health',
  Humanitarian = 'Humanitarian',
  Other = 'Other',
  Politic = 'Politic',
  Religion = 'Religion',
  SocialService = 'SocialService',
  SportsAndLeisure = 'SportsAndLeisure',
  StudentGroup = 'StudentGroup',
  Veterans = 'Veterans'
}

export enum OrganizationCause {
  Education = 'Education',
  Nature = 'Nature',
  Solidarity = 'Solidarity',
  SportAndCulture = 'SportAndCulture'
}

export enum OrganizationCountry {
  Canada = 'Canada',
  GreatBritain = 'GreatBritain',
  UnitedStates = 'UnitedStates'
}

export type OrganizationDetailsObject = {
  __typename?: 'OrganizationDetailsObject';
  closingDate?: Maybe<Scalars['Date']>;
  fundsRaised: Scalars['Float'];
  numberContacts: Scalars['Float'];
  numberForms: Scalars['Float'];
  numberOfReferrals: Scalars['Float'];
  numberPayments: Scalars['Float'];
  numberUserAccounts: Scalars['Float'];
};

export type OrganizationEngagementFlagsObject = {
  __typename?: 'OrganizationEngagementFlagsObject';
  engagementAddContactTour: Scalars['Boolean'];
  engagementContactNotesTour: Scalars['Boolean'];
  engagementContactPaymentsTour: Scalars['Boolean'];
  engagementContactTagsTour: Scalars['Boolean'];
  engagementContactsBulkTour: Scalars['Boolean'];
  engagementEmailRecipientsTour: Scalars['Boolean'];
  engagementEmailStatsTour: Scalars['Boolean'];
  engagementEmailTemplatesTour: Scalars['Boolean'];
  engagementPaymentDetailsTour: Scalars['Boolean'];
  engagementPaymentTour: Scalars['Boolean'];
  engagementPaymentsExportTour: Scalars['Boolean'];
  id: Scalars['String'];
};

export type OrganizationEngagementFlagsObject9p8Kt0Yx = {
  __typename?: 'OrganizationEngagementFlagsObject9p8Kt0Yx';
  error?: Maybe<OrganizationEngagementFlagsObject9p8Kt0YxErrorObject>;
  object?: Maybe<OrganizationEngagementFlagsObject>;
};

export type OrganizationEngagementFlagsObject9p8Kt0YxErrorObject = {
  __typename?: 'OrganizationEngagementFlagsObject9p8Kt0YxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationFlagsInput = {
  demotivateEmbeds?: InputMaybe<AmplitudeVariantValue>;
};

export type OrganizationGroupObject = {
  __typename?: 'OrganizationGroupObject';
  id: Scalars['String'];
  organization: OrganizationObject;
  organizationId: Scalars['String'];
  permissions: Array<UserPermissions>;
  role: UserRoles;
  user: UserObject;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  activationChecklistExperiment?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  badTipperHubspotFlag?: Maybe<Scalars['Boolean']>;
  balanceTransferDelayDay: Scalars['Float'];
  bankDescriptor?: Maybe<Scalars['String']>;
  blockPayment: Scalars['Boolean'];
  canGenerateReceipt: Scalars['Boolean'];
  cardMaximumAmount?: Maybe<Scalars['Int']>;
  category?: Maybe<OrganizationCategory>;
  cause?: Maybe<OrganizationCause>;
  charityNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  createdAtUtc?: Maybe<Scalars['Date']>;
  demotivateEmbeds?: Maybe<AmplitudeVariantValue>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  dismissOnboardingOnFormPageAt?: Maybe<Scalars['Date']>;
  engagementCheckListStepChecked?: Maybe<Array<Scalars['String']>>;
  engagementChecklistExperiment?: Maybe<AmplitudeVariantValue>;
  featureIssuingEnabled: Scalars['Boolean'];
  featureTreasuryEnabled: Scalars['Boolean'];
  firstPaymentDate?: Maybe<Scalars['Date']>;
  fourthPaymentDate?: Maybe<Scalars['Date']>;
  fraudulent: Scalars['Boolean'];
  fraudulentAtUtc?: Maybe<Scalars['Date']>;
  hasAdministrativeInformation: Scalars['Boolean'];
  hasAtLeastOnePayment?: Maybe<Scalars['Boolean']>;
  hasCharityNumber: Scalars['Boolean'];
  hasDismissActivationPayoutSuccessCard?: Maybe<Scalars['Boolean']>;
  hasFinishZeffyOnboarding: Scalars['Boolean'];
  hasImportGmailContacts?: Maybe<Scalars['Boolean']>;
  hasImportMailchimpContacts?: Maybe<Scalars['Boolean']>;
  hasStripeCustomFlowStarted: Scalars['Boolean'];
  hasVisitorReports?: Maybe<Scalars['Boolean']>;
  hideFromZeffyMap: Scalars['Boolean'];
  hubspotNpoStarterDealId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isPaymentDisabled?: Maybe<Scalars['Boolean']>;
  isPoBoxAddress?: Maybe<Scalars['Boolean']>;
  isStripeAccountDeprecated?: Maybe<Scalars['Boolean']>;
  isStripeCustomAccountActive: Scalars['Boolean'];
  lastPaymentDate?: Maybe<Scalars['Date']>;
  legalName?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npoStarterStateCertificateUrl?: Maybe<Scalars['String']>;
  npoStarterToDoListStepChecked?: Maybe<Array<Scalars['String']>>;
  onboardedFormCategory?: Maybe<FormCategory>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  owner?: Maybe<UserObject>;
  ownerEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  previewFirstFormAt?: Maybe<Scalars['Date']>;
  propublicaCCode?: Maybe<Scalars['Float']>;
  referralAmountLimit: Scalars['Float'];
  referralQuestion?: Maybe<ReferralQuestionChoice>;
  referralQuestionOther?: Maybe<Scalars['String']>;
  referredByOrganizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  stripeCustomAccountActiveAtUtc?: Maybe<Scalars['Date']>;
  stripeCustomAccountId?: Maybe<Scalars['String']>;
  stripeTopupSourceId?: Maybe<Scalars['String']>;
  stripeUserId?: Maybe<Scalars['String']>;
  stripeVerificationSucceededBannerShown?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagObject>>;
  type?: Maybe<OrganizationType>;
  volumeThresholdReached?: Maybe<Scalars['Float']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationObjectRyNeXqbs = {
  __typename?: 'OrganizationObjectRyNeXqbs';
  error?: Maybe<OrganizationObjectRyNeXqbsErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectRyNeXqbsErrorObject = {
  __typename?: 'OrganizationObjectRyNeXqbsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationObjectrvAByN9e = {
  __typename?: 'OrganizationObjectrvAByN9e';
  error?: Maybe<OrganizationObjectrvAByN9eErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectrvAByN9eErrorObject = {
  __typename?: 'OrganizationObjectrvAByN9eErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicObject = {
  __typename?: 'OrganizationPublicObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<OwnerObject>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrganizationPublicResponseListObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicResponseObject = {
  __typename?: 'OrganizationPublicResponseObject';
  error?: Maybe<OrganizationPublicResponseObjectErrorObject>;
  object?: Maybe<OrganizationPublicObject>;
};

export type OrganizationPublicResponseObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseListObjectErrorObject = {
  __typename?: 'OrganizationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseObject = {
  __typename?: 'OrganizationResponseObject';
  error?: Maybe<OrganizationResponseObjectErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationResponseObjectErrorObject = {
  __typename?: 'OrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationType {
  Charity = 'Charity',
  Other = 'Other',
  Other501C = 'Other501C',
  Private = 'Private',
  Social = 'Social'
}

export type OverTimeFilterInput = {
  slot: OverTimeSlot;
};

export enum OverTimeSlot {
  fourteenDays = 'fourteenDays',
  oneMonth = 'oneMonth',
  sevenDays = 'sevenDays',
  seventyTwoHours = 'seventyTwoHours',
  twentyFourHours = 'twentyFourHours',
  twoHours = 'twoHours'
}

export type OwnerObject = {
  __typename?: 'OwnerObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type PageViewsSummaryItemObject = {
  __typename?: 'PageViewsSummaryItemObject';
  count: Scalars['Float'];
  time: Scalars['String'];
};

export type PageViewsSummaryObject = {
  __typename?: 'PageViewsSummaryObject';
  data?: Maybe<Array<PageViewsSummaryItemObject>>;
};

export type PageViewsSummaryResponseObject = {
  __typename?: 'PageViewsSummaryResponseObject';
  error?: Maybe<PageViewsSummaryResponseObjectErrorObject>;
  object?: Maybe<PageViewsSummaryObject>;
};

export type PageViewsSummaryResponseObjectErrorObject = {
  __typename?: 'PageViewsSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentIntentError {
  CardDeclined = 'CardDeclined',
  FormNotFound = 'FormNotFound',
  InsufficientCapabilitiesForTransfer = 'InsufficientCapabilitiesForTransfer',
  InsufficientFunds = 'InsufficientFunds',
  OwnerNotFound = 'OwnerNotFound',
  UnknownError = 'UnknownError'
}

export type PaymentIntentObject = {
  __typename?: 'PaymentIntentObject';
  clientSecret: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type PaymentIntentResponseObject = {
  __typename?: 'PaymentIntentResponseObject';
  error?: Maybe<PaymentIntentResponseObjectErrorObject>;
  object?: Maybe<PaymentIntentObject>;
};

export type PaymentIntentResponseObjectErrorObject = {
  __typename?: 'PaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentMethod {
  Ach = 'Ach',
  ApplePayOrGooglePay = 'ApplePayOrGooglePay',
  Card = 'Card',
  Cash = 'Cash',
  CashApp = 'CashApp',
  Cheque = 'Cheque',
  Free = 'Free',
  InKind = 'InKind',
  Manual = 'Manual',
  Other = 'Other',
  Pad = 'Pad',
  Stocks = 'Stocks',
  TapToPay = 'TapToPay',
  Transfer = 'Transfer',
  Unknown = 'Unknown'
}

export enum PaymentStatus {
  AwaitingTransfer = 'AwaitingTransfer',
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum PayoutInterval {
  Daily = 'Daily',
  Manual = 'Manual',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type PayoutObject = {
  __typename?: 'PayoutObject';
  amount: Scalars['Float'];
  arrivalDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  externalAccount: ExternalAccountObject;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
};

export type PayoutResponseObject = {
  __typename?: 'PayoutResponseObject';
  error?: Maybe<PayoutResponseObjectErrorObject>;
  object?: Maybe<PayoutObject>;
};

export type PayoutResponseObjectErrorObject = {
  __typename?: 'PayoutResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PayoutsInfiniteListResponseObjectErrorObject = {
  __typename?: 'PayoutsInfiniteListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PostSubmitCommandObject = {
  __typename?: 'PostSubmitCommandObject';
  createFundraisingHandler?: Maybe<CreatedCampaignsObject>;
};

export type PostSubmitCommandObjectrYjXVo7U = {
  __typename?: 'PostSubmitCommandObjectrYjXVo7U';
  error?: Maybe<PostSubmitCommandObjectrYjXVo7UErrorObject>;
  object?: Maybe<PostSubmitCommandObject>;
};

export type PostSubmitCommandObjectrYjXVo7UErrorObject = {
  __typename?: 'PostSubmitCommandObjectrYjXVo7UErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PreviewReceiptParams = {
  amount?: InputMaybe<Scalars['Float']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  formType: FormType;
  locale?: InputMaybe<Scalars['String']>;
  rateName?: InputMaybe<Scalars['String']>;
  ticketingName?: InputMaybe<Scalars['String']>;
};

export type PreviewTicketParams = {
  address?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  endUtc?: InputMaybe<Scalars['Date']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  formTitle?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  rateTitle?: InputMaybe<Scalars['String']>;
  startUtc?: InputMaybe<Scalars['Date']>;
  ticketingColor?: InputMaybe<Scalars['String']>;
};

export type ProcessBalanceTransferInput = {
  offsetDay: Scalars['Float'];
};

export type ProductBidObject = {
  __typename?: 'ProductBidObject';
  amount: Scalars['Int'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  rate?: Maybe<RateObject>;
  rateId: Scalars['String'];
  status: ProductBidStatus;
};

export type ProductBidObjectXYdTmGh0 = {
  __typename?: 'ProductBidObjectXYdTMGh0';
  error?: Maybe<ProductBidObjectXYdTmGh0ErrorObject>;
  items?: Maybe<Array<ProductBidObject>>;
};

export type ProductBidObjectXYdTmGh0ErrorObject = {
  __typename?: 'ProductBidObjectXYdTMGh0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductBidStatus {
  Lost = 'Lost',
  Submitted = 'Submitted',
  Won = 'Won'
}

export type ProductDonationObject = {
  __typename?: 'ProductDonationObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  id: Scalars['String'];
  inHonourEmailAddress?: Maybe<Scalars['String']>;
  inHonourEmailBody?: Maybe<Scalars['String']>;
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  isRecurrent: Scalars['Boolean'];
  organizationId: Scalars['String'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: Maybe<Scalars['String']>;
};

export enum ProductDonationRecurrenceInterval {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Yearly = 'Yearly'
}

export type ProductDonationResponseObjectErrorObject = {
  __typename?: 'ProductDonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketCountsObject = {
  __typename?: 'ProductTicketCountsObject';
  id: Scalars['String'];
  productTicketCheckedIn: Scalars['Int'];
  productTicketTotal: Scalars['Int'];
};

export type ProductTicketCountsObjectCUdLhdJ7 = {
  __typename?: 'ProductTicketCountsObjectCUdLhdJ7';
  error?: Maybe<ProductTicketCountsObjectCUdLhdJ7ErrorObject>;
  object?: Maybe<ProductTicketCountsObject>;
};

export type ProductTicketCountsObjectCUdLhdJ7ErrorObject = {
  __typename?: 'ProductTicketCountsObjectCUdLhdJ7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketListResponseObject = {
  __typename?: 'ProductTicketListResponseObject';
  error?: Maybe<ProductTicketListResponseObjectErrorObject>;
  items?: Maybe<Array<ProductTicketObject>>;
};

export type ProductTicketListResponseObjectErrorObject = {
  __typename?: 'ProductTicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketObject = {
  __typename?: 'ProductTicketObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal?: Maybe<Scalars['Boolean']>;
  command?: Maybe<CommandObject>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  expirationDate?: Maybe<Scalars['Date']>;
  groupTicketId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  status: ProductTicketStatus;
  ticketingIndex?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type ProductTicketObjectwvIrya5S = {
  __typename?: 'ProductTicketObjectwvIRYA5S';
  error?: Maybe<ProductTicketObjectwvIrya5SErrorObject>;
  object?: Maybe<ProductTicketObject>;
};

export type ProductTicketObjectwvIrya5SErrorObject = {
  __typename?: 'ProductTicketObjectwvIRYA5SErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketResponseObjectErrorObject = {
  __typename?: 'ProductTicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductTicketStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  CheckedIn = 'CheckedIn',
  Pending = 'Pending'
}

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject';
  cancelledCount: Scalars['String'];
  checkedInCount: Scalars['String'];
  filteredRowsEmails: Array<Scalars['String']>;
  notCheckedInCount: Scalars['String'];
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectBzWiWHlW = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectBZWiWHlW';
  error?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObjectBzWiWHlWErrorObject>;
  object?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObject>;
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectBzWiWHlWErrorObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectBZWiWHlWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkIfResetPasswordIsValid: BooleanqX9Ny8xl;
  defaultQuery: Scalars['Boolean'];
  doesOrganizationHaveProductBids: BooleanB1ZGeeX7;
  donationForm: DonationFormObjectsNwXqmPr;
  donationFormWithRestrictedAccess: DonationFormObjectat41XcXp;
  fetchAndSetSkipAIChat: BooleanXvXs8MNj;
  generateCheckinManagerUrl: StringvMkQ50rW;
  getActiveTicketingOccurrenceIds: StringJroCe4Co;
  getAggregateProductTicketStatusCountPerTicketingPerOccurrence: ProductTicketStatusCountPerTicketingPerOccurrenceObjectBzWiWHlW;
  getAggregateRateFieldsByCommand: AggregateRateFieldsObject8v6iiYm9;
  getApplicationInitialStatus: ApplicationInitialStatusResponseObject;
  getBalanceTransactionDetails: GetBalanceTransactionDetailsObjectYDiInx4J;
  getCampaignFormsCount: NumberIyxglVqP;
  getCampaignParticipantContacts: ContactObjectas8fyPop;
  getCampaignSubFormsCount: NumberResponseObject;
  getCampaignWithVolume?: Maybe<DonationFormResponseObject>;
  getCardVolume: CardVolumeResponseObject;
  getCommandAnswers: AnswerObjectE23vpcE6;
  getContactCountByTag: NumberResponseObject;
  getContactDetails: ContactObject6zpUg36D;
  getContactKeyInfo: ContactKeyInfoObjectxRb355qy;
  getContactsFormRecipientIds: ContactObjectIrYfiuit;
  getCurrentUser?: Maybe<GetCurrentUserObject>;
  getCurrentlyActiveOrganizations: CurrentlyActiveOrganizationResponseListObject;
  getDiscount: DiscountResponseObject;
  getDonationFormForManualDonation: DonationFormObject6CslF9Wf;
  getDonationFormQuestions: QuestionListResponseObject;
  getDonationFormWithFundraiserAccess: DonationFormObjectZMyX8Vou;
  getDonationFormsFromCampaign: GetDonationFormsFromCampaignObjectrtXt8WHt;
  getDonationFormsList: DonationFormObject4hyGOivq;
  getDonorVolume: DonorVolumeListResponseObject;
  getEmailDelivery: EmailDeliveryObjectfHxcFbkO;
  getEmailTemplates: EmailTemplateResponseListObject;
  getEmailsCountByStatus: EmailsCountByStatusTableListResponseObject;
  getEphemeralKey: GetEphemeralKeyObjectOZzvPjVd;
  getFirstTransactionCreatedAtUtc: GetFirstTransactionCreatedAtUtcResponseObjectKQzvSRmU;
  getFormVolume: NumberEMpOq5Bd;
  getFrontendCurrentUser: GetCurrentUserFrontendObjectXaoqBlng;
  getFundraisingOrganization: OrganizationResponseObject;
  getHomeGraphData: HomeGraphResponseListObject;
  getImportStatus: ImportUsersGoogleSheetObjectqQ1xjHqr;
  getIsActivatedFeatureToggleBadTipperDisclaimer: BooleannzkFDiTc;
  getIsNewMobileFormCreationFlow: BooleanDboReyeE;
  getIssuingCard: GetIssuingCardObjectyEpJvwDr;
  getIssuingRewardTransactions: GetIssuingRewardTransactionsObjectMzt4RSot;
  getMarkerHoverDetails: MarkerHoverDetailsObject;
  getMarkerPositions: ClusterResponseObject;
  getMyReferralOrganizations: MyReferralOrganizationObjectycB3jbou;
  getMyReferrerInformation: MyReferrerOrganizationResponseObject;
  getNextReferralTransferAmount: NumberResponseObject;
  getNotes: NoteResponseListObject;
  getOrganizationAdmin: UserObject5iSfSCjz;
  getOrganizationEngagementFlags: OrganizationEngagementFlagsObject9p8Kt0Yx;
  getOrganizationForms: FrontendFormObjectResponseListObject;
  getOrganizationHasAnyTag: NumberResponseObject;
  getOrganizationListByInput: OrganizationAndOwnerObjectFfOyiHbm;
  getOrganizationMembers: UserListResponseObject;
  getOrganizationOwner: UserResponseObject;
  getOrganizationSummary: GetOrganizationSummaryResponseObject;
  getOrganizationTags: TagListResponseObject;
  getPageViewsSummary: PageViewsSummaryResponseObject;
  getPayout: PayoutResponseObject;
  getProductTicketCountsForCheckin: ProductTicketCountsObjectCUdLhdJ7;
  getProductTicketsCount: NumberResponseObjectnt1dMfMj;
  getReferredOrganization: OrganizationResponseObject;
  getScheduledPayout: GetScheduledPayoutObjectKrs7NqTk;
  getSentryIssues: SentryIssueListResponseObject;
  getStripeAccountBalance: AccountBalancesObject2dhjTz1U;
  getStripeAccountLink: Stringaab2xdbY;
  getStripeCustomAccount: StripeAccountResponseObject;
  getTags: TagListResponseObject;
  getTagsByContactIds: ContactIdWithTagsObjectbXvzDxy0;
  getTeamMembers: DonationFormObjectTsQxml1m;
  getTeamWithVolume: DonationFormObjectZepdrNdV;
  getTeamsFromCampaignId: Array<DonationFormObject>;
  getTicketing: TicketingObjectFXrcAt2x;
  getTicketingCategory: TicketingCategoryObject97lyJ2Rr;
  getTicketingForManualOrder: TicketingObjecta5wC2VpY;
  getTicketingInfoForCheckin: TicketingObject3J2JxhWf;
  getTicketingList: TicketingObjectjlhvGdUs;
  getTicketingReminderData: TicketingFieldObjectUdoemjrv;
  getTipAndFeeAndRefundTipVolumeFromTransaction: TipNetVolumeResponseObject;
  getTipSuggestions: Array<TipSuggestionObject>;
  getTransaction: TransactionResponseObject;
  getTransactionCountOverTime: TransactionsOverTimeListResponseObject;
  getTransactionWithCommand: TransactionResponseObject;
  getTransactionsForIssuingBalanceTransfer: GetTransactionsForIssuingBalanceTransferObjectUaNwLiTa;
  getUnsubscribePreferences: UnsubscribePreferencesObjectkuWdqEy2;
  getVolumeAtDate: NumberResponseObject;
  getWebAnalytics: WebAnalyticsResponseObject;
  getWebhooksCountOverTime: WebhooksOverTimeListResponseObject;
  hasAnOccurrenceBefore: Boolean5pWx8lBr;
  hasAnyReceipt: BooleanrQkyRpmw;
  hasContacts: BooleanResponseObject;
  hasEmailDelivery: HasEmailDeliveryObjectw4jMqfag;
  isAnExistingUser: BooleanHEfg9Zgc;
  listCampaignFormTitles: FormTitleListResponse;
  listCommandsFromByOccurrenceId: CommandObjectBgbN4l2a;
  listDashboardCommandBidRates: BidRateObjectJcx6grln;
  listDashboardCommandForms: DashboardCommandFormListResponse;
  listDashboardCommandRates: DashboardCommandRatesListResponseObject;
  listDashboardContactTransactionsCount: DashboardCountObjectGNquviGe;
  listDashboardTransactionsRates: TransactionRatesWithQuantityObjectclqq78wV;
  listDonorDashboardCommandForms: DashboardCommandFormListResponse;
  listDonorDashboardTransactionsCount: IntResponseObject;
  listExpiringTransactionTickets: ProductTicketListResponseObject;
  listFormsForManualCommand: FrontendFormObjectx7Nd0fU1;
  listOrganizationFormTitles: FormTitleListResponse;
  listQuestionsWithChoices: QuestionListResponseObject;
  listTransactionBids: ProductBidObjectXYdTmGh0;
  listTransactionTickets: ListTransactionTicketsResponse;
  listTransactionsWithFullyCanceledTickets: StringListResponseObject;
  organizationFormsAsMultiselectValues: MultiselectValueObjectltiNoL9e;
  organizationOccurrencesAsMultiselectValues: MultiselectOccurrenceValueObjectungfSp3c;
  organizationTagsAsMultiselectValues: MultiselectValueResponseListObject;
  retrieveCommand: CommandObjectXmcEaesL;
  retrieveCommandAnswers: AnswerResponseListObject;
  retrieveContact: ContactResponseObject;
  retrieveDashboardTransaction: DashboardTransactionObjectWy3WdGcO;
  retrieveDonorCommand: CommandObjectbeyhhTzQ;
  retrieveDonorDashboardSubscription: CommandSubscriptionResponseObject;
  retrieveDonorDashboardTransaction: DashboardTransactionObject043qfsTb;
  retrieveDonorReceipt: ReceiptResponseObject;
  retrieveForm: FrontendFormObjectResponseObject;
  retrieveOrganization: OrganizationPublicResponseObject;
  retrieveProductTicketAnswers: AnswerObject8Xa8QCg1;
  retrieveProductTicketAnswersForCheckin: AnswerObject49Bffj0p;
  retrieveReceipt: ReceiptResponseObject;
  retrieveTransactionReceipts: ReceiptObjectFytCqSag;
  retrieveUnsubscribeContactInformations: ContactResponseObject;
  searchNonprofit: NewNonprofitObjectDmvCwOdP;
  searchUnsplashImages: SearchUnsplashImagesResponseObject;
  shouldDisplayPostOccurrenceThankYouEmail: Booleanyy9IdbqI;
  ticketingRatesAsMultiselectValues: MultiselectValueObject4a6oxS0o;
};


export type QueryCheckIfResetPasswordIsValidArgs = {
  checkIfResetPasswordIsValidInput: CheckIfResetPasswordIsValidInput;
};


export type QueryDonationFormArgs = {
  donationFormIdOrUrlPath: Scalars['String'];
};


export type QueryDonationFormWithRestrictedAccessArgs = {
  donationFormIdInput: UuidInput;
};


export type QueryGenerateCheckinManagerUrlArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetActiveTicketingOccurrenceIdsArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateProductTicketStatusCountPerTicketingPerOccurrenceArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateRateFieldsByCommandArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetBalanceTransactionDetailsArgs = {
  balanceTransactionId: Scalars['String'];
  type: BalanceTransactionType;
};


export type QueryGetCampaignFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignParticipantContactsArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignSubFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignWithVolumeArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCardVolumeArgs = {
  parameters?: InputMaybe<HomeMetricsInput>;
};


export type QueryGetCommandAnswersArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetContactCountByTagArgs = {
  tagId: Scalars['String'];
};


export type QueryGetContactDetailsArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactKeyInfoArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactsFormRecipientIdsArgs = {
  recipientIds: Array<Scalars['String']>;
};


export type QueryGetCurrentlyActiveOrganizationsArgs = {
  input?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountArgs = {
  id: Scalars['String'];
};


export type QueryGetDonationFormForManualDonationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormQuestionsArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormWithFundraiserAccessArgs = {
  donationFormIdInput: UuidInput;
  token: Scalars['String'];
};


export type QueryGetDonationFormsFromCampaignArgs = {
  campaignId: Scalars['String'];
  filters: DonationFormCampaignFilterInput;
};


export type QueryGetDonationFormsListArgs = {
  withArchived: Scalars['Boolean'];
};


export type QueryGetEmailDeliveryArgs = {
  emailDeliveryIdInput: UuidInput;
};


export type QueryGetEmailsCountByStatusArgs = {
  emailDeliveryId: Scalars['String'];
};


export type QueryGetEphemeralKeyArgs = {
  input: GetEphemeralKeyInput;
};


export type QueryGetFormVolumeArgs = {
  formInput: FormInput;
};


export type QueryGetFundraisingOrganizationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetHomeGraphDataArgs = {
  parameters: HomeGraphInput;
};


export type QueryGetImportStatusArgs = {
  importId: Scalars['String'];
};


export type QueryGetMarkerHoverDetailsArgs = {
  getMarkerHoverDetailsInput: GetMarkerHoverDetailsInput;
};


export type QueryGetMarkerPositionsArgs = {
  getMarkerInput: GetMarkersInput;
};


export type QueryGetNotesArgs = {
  userId: Scalars['String'];
};


export type QueryGetOrganizationAdminArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationFormsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationListByInputArgs = {
  searchOragnizationInput: Scalars['String'];
};


export type QueryGetOrganizationOwnerArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationSummaryArgs = {
  locale: Locales;
  organizationId: Scalars['String'];
};


export type QueryGetPageViewsSummaryArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetPayoutArgs = {
  id: Scalars['String'];
};


export type QueryGetProductTicketCountsForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetProductTicketsCountArgs = {
  commandId: Scalars['String'];
};


export type QueryGetReferredOrganizationArgs = {
  params: GetReferredOrganizationInput;
};


export type QueryGetSentryIssuesArgs = {
  slot: SentrySlot;
};


export type QueryGetTagsArgs = {
  userId: Scalars['String'];
};


export type QueryGetTagsByContactIdsArgs = {
  contactIds: Array<Scalars['String']>;
};


export type QueryGetTeamMembersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamWithVolumeArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamsFromCampaignIdArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingCategoryArgs = {
  params: UuidInput;
};


export type QueryGetTicketingForManualOrderArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingInfoForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetTicketingReminderDataArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTipSuggestionsArgs = {
  getTipSuggestionsInput: GetTipSuggestionsInput;
};


export type QueryGetTransactionArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetTransactionWithCommandArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionsForIssuingBalanceTransferArgs = {
  input: ProcessBalanceTransferInput;
};


export type QueryGetVolumeAtDateArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGetWebAnalyticsArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetWebhooksCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryHasAnOccurrenceBeforeArgs = {
  days: Scalars['Int'];
  ticketingId: Scalars['String'];
};


export type QueryIsAnExistingUserArgs = {
  email: Scalars['String'];
};


export type QueryListCampaignFormTitlesArgs = {
  campaignId: Scalars['String'];
};


export type QueryListCommandsFromByOccurrenceIdArgs = {
  occurrenceId: Scalars['String'];
};


export type QueryListDashboardCommandBidRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListDashboardCommandRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardContactTransactionsCountArgs = {
  userId: Scalars['String'];
};


export type QueryListDashboardTransactionsRatesArgs = {
  locale: Locales;
  transactionIds: Array<Scalars['String']>;
};


export type QueryListDonorDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListExpiringTransactionTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryListQuestionsWithChoicesArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  questionIds: Array<Scalars['String']>;
};


export type QueryListTransactionBidsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionTicketsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionsWithFullyCanceledTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryOrganizationOccurrencesAsMultiselectValuesArgs = {
  formIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRetrieveCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveCommandAnswersArgs = {
  commandId: Scalars['String'];
};


export type QueryRetrieveContactArgs = {
  contactId: Scalars['String'];
};


export type QueryRetrieveDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveFormArgs = {
  formInput: FormInput;
};


export type QueryRetrieveOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveProductTicketAnswersArgs = {
  productTicketIds: Array<Scalars['String']>;
};


export type QueryRetrieveProductTicketAnswersForCheckinArgs = {
  productTicketIds: Array<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryRetrieveReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveTransactionReceiptsArgs = {
  transactionId: Scalars['String'];
};


export type QueryRetrieveUnsubscribeContactInformationsArgs = {
  contactId: Scalars['String'];
};


export type QuerySearchNonprofitArgs = {
  filters: SearchNonprofitFilterInput;
  query: Scalars['String'];
};


export type QuerySearchUnsplashImagesArgs = {
  searchUnsplashImagesInput: SearchUnsplashImagesInput;
};


export type QueryShouldDisplayPostOccurrenceThankYouEmailArgs = {
  occurrenceIdInput: UuidInput;
};


export type QueryTicketingRatesAsMultiselectValuesArgs = {
  formId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  questionId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldObject = {
  __typename?: 'QuestionFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type QuestionInput = {
  choices: Array<ChoiceInput>;
  donationFormId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  questionFields: Array<QuestionFieldInput>;
  ratesAssigned?: InputMaybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  ticketingId?: InputMaybe<Scalars['String']>;
  type: QuestionType;
};

export type QuestionListResponseObject = {
  __typename?: 'QuestionListResponseObject';
  error?: Maybe<QuestionListResponseObjectErrorObject>;
  items?: Maybe<Array<QuestionObject>>;
};

export type QuestionListResponseObjectErrorObject = {
  __typename?: 'QuestionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type QuestionObject = {
  __typename?: 'QuestionObject';
  answers?: Maybe<Array<AnswerObject>>;
  choices: Array<ChoiceObject>;
  countAlreadyAnswered: Scalars['Float'];
  hasRate: Scalars['Boolean'];
  id: Scalars['String'];
  questionFields: Array<QuestionFieldObject>;
  rateQuestions?: Maybe<Array<RateQuestionObject>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  type: QuestionType;
};

export enum QuestionType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  Email = 'Email',
  Multiple = 'Multiple',
  MultipleCheckboxes = 'MultipleCheckboxes',
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Simple = 'Simple',
  Utm = 'Utm'
}

export type RateByOccurrenceObject = {
  __typename?: 'RateByOccurrenceObject';
  rateId: Scalars['String'];
  remainingTickets?: Maybe<Scalars['Float']>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
};

export type RateFieldObject = {
  __typename?: 'RateFieldObject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type RateFieldObjectWithCount = {
  __typename?: 'RateFieldObjectWithCount';
  attendeesByTicket?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  locale: Locales;
  rateFieldCount: Scalars['Float'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type RateListResponseObjectErrorObject = {
  __typename?: 'RateListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RateObject = {
  __typename?: 'RateObject';
  allowAutomaticRenewal?: Maybe<Scalars['Boolean']>;
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  attendeesByTicket?: Maybe<Scalars['Float']>;
  bidIncrement?: Maybe<Scalars['Float']>;
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isRateFull?: Maybe<Scalars['Boolean']>;
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: Maybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: Maybe<Scalars['Date']>;
  minimumToBuy?: Maybe<Scalars['Float']>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  rateFields?: Maybe<Array<RateFieldObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  tickets?: Maybe<Array<TicketObject>>;
};

export type RateQuestionObject = {
  __typename?: 'RateQuestionObject';
  id: Scalars['String'];
  rateId: Scalars['String'];
};

export type RatesWithQuantityObject = {
  __typename?: 'RatesWithQuantityObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type ReceiptListResponseObjectErrorObject = {
  __typename?: 'ReceiptListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObject = {
  __typename?: 'ReceiptObject';
  address?: Maybe<Scalars['String']>;
  advantageAmount?: Maybe<Scalars['Float']>;
  advantageDescription?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  charityNumber: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  issuingAtUtc: Scalars['Date'];
  lastName?: Maybe<Scalars['String']>;
  organizationAddress: Scalars['String'];
  organizationCity: Scalars['String'];
  organizationCountry: OrganizationCountry;
  organizationName: Scalars['String'];
  organizationPostalCode: Scalars['String'];
  organizationRegion: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  receiptNumber: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  status: ReceiptStatus;
  transactions?: Maybe<Array<TransactionObject>>;
  userId?: Maybe<Scalars['String']>;
};

export type ReceiptObjectFytCqSag = {
  __typename?: 'ReceiptObjectFYTCqSag';
  error?: Maybe<ReceiptObjectFytCqSagErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectFytCqSagErrorObject = {
  __typename?: 'ReceiptObjectFYTCqSagErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObjectJvLjnbq3 = {
  __typename?: 'ReceiptObjectJVLjnbq3';
  error?: Maybe<ReceiptObjectJvLjnbq3ErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectJvLjnbq3ErrorObject = {
  __typename?: 'ReceiptObjectJVLjnbq3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptResponseObject = {
  __typename?: 'ReceiptResponseObject';
  error?: Maybe<ReceiptResponseObjectErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptResponseObjectErrorObject = {
  __typename?: 'ReceiptResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ReceiptStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Pending = 'Pending'
}

export type ReceiptTableListResponseObjectErrorObject = {
  __typename?: 'ReceiptTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RecipientObject = {
  __typename?: 'RecipientObject';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
};

export type RedirectToUrlObject = {
  __typename?: 'RedirectToUrlObject';
  returnUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum ReferralQuestionChoice {
  AsDonor = 'AsDonor',
  Capterra = 'Capterra',
  Nbctc = 'Nbctc',
  OrganicSearch = 'OrganicSearch',
  Other = 'Other',
  ReferralFromDonor = 'ReferralFromDonor',
  ReferralFromOrganization = 'ReferralFromOrganization',
  SocialNetwork = 'SocialNetwork'
}

export type RefundObject = {
  __typename?: 'RefundObject';
  id: Scalars['String'];
};

export type RefundResponseObject = {
  __typename?: 'RefundResponseObject';
  error?: Maybe<RefundResponseObjectErrorObject>;
  object?: Maybe<RefundObject>;
};

export type RefundResponseObjectErrorObject = {
  __typename?: 'RefundResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};

export enum ResetPasswordLinkError {
  EmailDoesNotExist = 'EmailDoesNotExist'
}

export type ResetPasswordLinkObject = {
  __typename?: 'ResetPasswordLinkObject';
  user?: Maybe<UserObject>;
};

export type ResetPasswordLinkResponseObject = {
  __typename?: 'ResetPasswordLinkResponseObject';
  error?: Maybe<ResetPasswordLinkResponseObjectErrorObject>;
  object?: Maybe<ResetPasswordLinkObject>;
};

export type ResetPasswordLinkResponseObjectErrorObject = {
  __typename?: 'ResetPasswordLinkResponseObjectErrorObject';
  code?: Maybe<ResetPasswordLinkError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RetroActiveReferralInput = {
  orgReferredEmail: Scalars['String'];
  orgReferringEmail: Scalars['String'];
};

export type ScheduledPayoutObject = {
  __typename?: 'ScheduledPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type SearchNonprofitFilterInput = {
  country?: InputMaybe<OrganizationCountry>;
};

export type SearchUnsplashImagesInput = {
  orderBy: UnsplashOrderBy;
  orientation?: InputMaybe<UnsplashOrientation>;
  page: Scalars['Float'];
  query?: InputMaybe<Scalars['String']>;
};

export type SearchUnsplashImagesObject = {
  __typename?: 'SearchUnsplashImagesObject';
  nextPage: Scalars['Float'];
  results: Array<UnsplashImageObject>;
  total: Scalars['Float'];
  total_pages: Scalars['Float'];
};

export type SearchUnsplashImagesResponseObject = {
  __typename?: 'SearchUnsplashImagesResponseObject';
  error?: Maybe<SearchUnsplashImagesResponseObjectErrorObject>;
  object?: Maybe<SearchUnsplashImagesObject>;
};

export type SearchUnsplashImagesResponseObjectErrorObject = {
  __typename?: 'SearchUnsplashImagesResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SendEmailDeliveryError {
  DesignConfigMissing = 'DesignConfigMissing',
  EmailDeliveryNotFound = 'EmailDeliveryNotFound',
  FailedToExportUnlayerHtml = 'FailedToExportUnlayerHtml',
  FailedToGetOwnerEmail = 'FailedToGetOwnerEmail',
  FailedToGetSmartInviteRecipients = 'FailedToGetSmartInviteRecipients',
  LimitExceeded = 'LimitExceeded',
  ManyRecipientsInPreview = 'ManyRecipientsInPreview',
  SenderNoEmail = 'SenderNoEmail',
  SendingFailed = 'SendingFailed',
  StripeAccountNotActive = 'StripeAccountNotActive',
  UnexpectedError = 'UnexpectedError'
}

export type SendEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'SendEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<SendEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendMergeUsersRequestResponseObject = {
  __typename?: 'SendMergeUsersRequestResponseObject';
  error?: Maybe<SendMergeUsersRequestResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type SendMergeUsersRequestResponseObjectErrorObject = {
  __typename?: 'SendMergeUsersRequestResponseObjectErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendResetPasswordRequestInput = {
  email: Scalars['String'];
  locale: Locales;
};

export type SentryIssueListResponseObject = {
  __typename?: 'SentryIssueListResponseObject';
  error?: Maybe<SentryIssueListResponseObjectErrorObject>;
  items?: Maybe<Array<SentryIssueObject>>;
};

export type SentryIssueListResponseObjectErrorObject = {
  __typename?: 'SentryIssueListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SentryIssueObject = {
  __typename?: 'SentryIssueObject';
  count: Scalars['Float'];
  culprit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  value?: Maybe<Scalars['String']>;
};

export type SentryIssueResponseObjectErrorObject = {
  __typename?: 'SentryIssueResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SentrySlot {
  OneHour = 'OneHour',
  SevenDays = 'SevenDays',
  SeventyTwoHours = 'SeventyTwoHours',
  TwentyFourHours = 'TwentyFourHours'
}

export type SessionObject = {
  __typename?: 'SessionObject';
  amount?: Maybe<Scalars['Float']>;
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
};

export type SessionResponseObject = {
  __typename?: 'SessionResponseObject';
  error?: Maybe<SessionResponseObjectErrorObject>;
  object?: Maybe<SessionObject>;
};

export type SessionResponseObjectErrorObject = {
  __typename?: 'SessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SignInAsAdministratorInput = {
  email: Scalars['String'];
  emailTarget: Scalars['String'];
  password: Scalars['String'];
};

export type SignInFrontendInput = {
  email?: InputMaybe<Scalars['String']>;
  googleToken?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  smsCode?: InputMaybe<Scalars['String']>;
  targetOrganizationId?: InputMaybe<Scalars['String']>;
  telemetryId?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};

export type SignInInput = {
  email: Scalars['String'];
  encryptPassword?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  charityNumber?: InputMaybe<Scalars['String']>;
  country: OrganizationCountry;
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleToken?: InputMaybe<Scalars['String']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  isNonprofitSearchSuccessful?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locale: Locales;
  npoStarterDealId?: InputMaybe<Scalars['String']>;
  organizationName: Scalars['String'];
  password: Scalars['String'];
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  referredByOrganizationId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<ReferralQuestionChoice>;
  ssoStytchData?: InputMaybe<SsoStytchData>;
  type?: InputMaybe<OrganizationType>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type SsoStytchData = {
  provider: Scalars['String'];
  stytchOrganizationId: Scalars['String'];
  stytchSessionJwt: Scalars['String'];
  stytchUserId: Scalars['String'];
};

export type String3aj7Ey9k = {
  __typename?: 'String3aj7Ey9k';
  error?: Maybe<String3aj7Ey9kErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String3aj7Ey9kErrorObject = {
  __typename?: 'String3aj7Ey9kErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type String9Ev8S10B = {
  __typename?: 'String9EV8S10B';
  error?: Maybe<String9Ev8S10BErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String9Ev8S10BErrorObject = {
  __typename?: 'String9EV8S10BErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringBhLiiTze = {
  __typename?: 'StringBhLIITze';
  error?: Maybe<StringBhLiiTzeErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringBhLiiTzeErrorObject = {
  __typename?: 'StringBhLIITzeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringJroCe4Co = {
  __typename?: 'StringJroCE4Co';
  error?: Maybe<StringJroCe4CoErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringJroCe4CoErrorObject = {
  __typename?: 'StringJroCE4CoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringListResponseObject = {
  __typename?: 'StringListResponseObject';
  error?: Maybe<StringListResponseObjectErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringListResponseObjectErrorObject = {
  __typename?: 'StringListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringResponseObject = {
  __typename?: 'StringResponseObject';
  error?: Maybe<StringResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringResponseObjectErrorObject = {
  __typename?: 'StringResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringZtN0cCxz = {
  __typename?: 'StringZtN0cCxz';
  error?: Maybe<StringZtN0cCxzErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringZtN0cCxzErrorObject = {
  __typename?: 'StringZtN0cCxzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Stringaab2xdbY = {
  __typename?: 'Stringaab2xdbY';
  error?: Maybe<Stringaab2xdbYErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type Stringaab2xdbYErrorObject = {
  __typename?: 'Stringaab2xdbYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringcizTzBnM = {
  __typename?: 'StringcizTzBnM';
  error?: Maybe<StringcizTzBnMErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringcizTzBnMErrorObject = {
  __typename?: 'StringcizTzBnMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringrdkSbr1N = {
  __typename?: 'StringrdkSbr1N';
  error?: Maybe<StringrdkSbr1NErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringrdkSbr1NErrorObject = {
  __typename?: 'StringrdkSbr1NErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringvMkQ50rW = {
  __typename?: 'StringvMkQ50rW';
  error?: Maybe<StringvMkQ50rWErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringvMkQ50rWErrorObject = {
  __typename?: 'StringvMkQ50rWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeAccountObject = {
  __typename?: 'StripeAccountObject';
  capabilities: StripeCapabilities;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalAccount?: Maybe<ExternalAccountObject>;
  future_requirements?: Maybe<StripeRequirements>;
  hasAtLeastOnePayout?: Maybe<Scalars['Boolean']>;
  hasOnlyOnePayout?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isBankConnected: Scalars['Boolean'];
  isRejectedByStripe?: Maybe<Scalars['Boolean']>;
  isRejectedByZeffy?: Maybe<Scalars['Boolean']>;
  lastPayout?: Maybe<PayoutObject>;
  payoutInterval: PayoutInterval;
  requirements?: Maybe<StripeRequirements>;
  type?: Maybe<Scalars['String']>;
};

export type StripeAccountResponseObject = {
  __typename?: 'StripeAccountResponseObject';
  error?: Maybe<StripeAccountResponseObjectErrorObject>;
  object?: Maybe<StripeAccountObject>;
};

export type StripeAccountResponseObjectErrorObject = {
  __typename?: 'StripeAccountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_issuing?: Maybe<StripeCapabilityStatus>;
  transfers?: Maybe<StripeCapabilityStatus>;
  treasury?: Maybe<StripeCapabilityStatus>;
};

export enum StripeCapabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type StripeCustomerObject = {
  __typename?: 'StripeCustomerObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  userId: Scalars['String'];
};

export enum StripePayoutStatus {
  canceled = 'canceled',
  failed = 'failed',
  inTransit = 'inTransit',
  paid = 'paid',
  pending = 'pending'
}

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  current_deadline?: Maybe<Scalars['Float']>;
  currently_due?: Maybe<Array<Scalars['String']>>;
  pending_verification?: Maybe<Array<Scalars['String']>>;
};

export enum SubscriptionRecurrenceInterval {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum SubscriptionStatus {
  Cancelled = 'Cancelled',
  InProgress = 'InProgress'
}

export type SwitchOrgAsAdministratorInput = {
  emailTarget: Scalars['String'];
};

export type TagListResponseObject = {
  __typename?: 'TagListResponseObject';
  error?: Maybe<TagListResponseObjectErrorObject>;
  items?: Maybe<Array<TagObject>>;
};

export type TagListResponseObjectErrorObject = {
  __typename?: 'TagListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TagObject = {
  __typename?: 'TagObject';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type TicketListResponseObjectErrorObject = {
  __typename?: 'TicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketObject = {
  __typename?: 'TicketObject';
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  order: OrderObject;
  orderId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  ticketingIndex?: Maybe<Scalars['Float']>;
};

export type TicketResponseObjectErrorObject = {
  __typename?: 'TicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingCategoryObject = {
  __typename?: 'TicketingCategoryObject';
  formCategory: TicketingFormCategory;
  id: Scalars['String'];
};

export type TicketingCategoryObject97lyJ2Rr = {
  __typename?: 'TicketingCategoryObject97lyJ2Rr';
  error?: Maybe<TicketingCategoryObject97lyJ2RrErrorObject>;
  object?: Maybe<TicketingCategoryObject>;
};

export type TicketingCategoryObject97lyJ2RrErrorObject = {
  __typename?: 'TicketingCategoryObject97lyJ2RrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingDetailsRate = {
  __typename?: 'TicketingDetailsRate';
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  fields: Array<RateFieldObject>;
  id: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
};

export type TicketingDonationFormObject = {
  __typename?: 'TicketingDonationFormObject';
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  campaignId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  registrationIncreaseThermometer: Scalars['Boolean'];
};

export type TicketingFeatureFlagObject = {
  __typename?: 'TicketingFeatureFlagObject';
  isPaymentByPadAllowed: Scalars['Boolean'];
};

export type TicketingFieldObject = {
  __typename?: 'TicketingFieldObject';
  bidLoserEmailContent?: Maybe<Scalars['String']>;
  bidLoserEmailObject?: Maybe<Scalars['String']>;
  chequeDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: Maybe<Scalars['String']>;
  postEventBody?: Maybe<Scalars['String']>;
  postEventSubject?: Maybe<Scalars['String']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  reminderBody?: Maybe<Scalars['String']>;
  reminderSubject?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TicketingFieldObjectUdoemjrv = {
  __typename?: 'TicketingFieldObjectUDOEMJRV';
  error?: Maybe<TicketingFieldObjectUdoemjrvErrorObject>;
  object?: Maybe<TicketingFieldObject>;
};

export type TicketingFieldObjectUdoemjrvErrorObject = {
  __typename?: 'TicketingFieldObjectUDOEMJRVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingFieldResponseObjectErrorObject = {
  __typename?: 'TicketingFieldResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum TicketingFormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  Shop = 'Shop'
}

export type TicketingListResponseObjectErrorObject = {
  __typename?: 'TicketingListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject = {
  __typename?: 'TicketingObject';
  address?: Maybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerPdfUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  color: Scalars['String'];
  coordinates: CoordinatesObject;
  discounts?: Maybe<Array<DiscountObject>>;
  displayAddressQuestion: Scalars['Boolean'];
  donationForm?: Maybe<TicketingDonationFormObject>;
  eventFollowupEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  eventReminderEmailContentId?: Maybe<Scalars['String']>;
  eventTimezone?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateExtraDonationReceipt: Scalars['Boolean'];
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isTicketingFull: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  moneyGathered?: Maybe<Scalars['Float']>;
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  occurrences?: Maybe<Array<TicketingOccurrenceObject>>;
  occurrencesWithRates?: Maybe<Array<OccurrenceWithRatesObject>>;
  orders?: Maybe<Array<OrderObject>>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  postEventEmailContentId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  postEventSendDateOffset?: Maybe<Scalars['Float']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionObject>>;
  rates?: Maybe<Array<RateObject>>;
  recurrences?: Maybe<Array<TicketingRecurrenceObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  reminderSendDateOffset?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  soldTicketsCount: Scalars['Float'];
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  thermometerTarget?: Maybe<Scalars['Float']>;
  ticketingFeatureFlagTreatment?: Maybe<TicketingFeatureFlagObject>;
  ticketingFields: Array<TicketingFieldObject>;
  withoutMap: Scalars['Boolean'];
};

export type TicketingObject3J2JxhWf = {
  __typename?: 'TicketingObject3J2JXHWf';
  error?: Maybe<TicketingObject3J2JxhWfErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject3J2JxhWfErrorObject = {
  __typename?: 'TicketingObject3J2JXHWfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject7ukBtN2n = {
  __typename?: 'TicketingObject7ukBtN2n';
  error?: Maybe<TicketingObject7ukBtN2nErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject7ukBtN2nErrorObject = {
  __typename?: 'TicketingObject7ukBtN2nErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectDBxIjb8p = {
  __typename?: 'TicketingObjectDBxIJB8p';
  error?: Maybe<TicketingObjectDBxIjb8pErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectDBxIjb8pErrorObject = {
  __typename?: 'TicketingObjectDBxIJB8pErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectFXrcAt2x = {
  __typename?: 'TicketingObjectFXrcAT2x';
  error?: Maybe<TicketingObjectFXrcAt2xErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectFXrcAt2xErrorObject = {
  __typename?: 'TicketingObjectFXrcAT2xErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectMYwZ960J = {
  __typename?: 'TicketingObjectMYwZ960J';
  error?: Maybe<TicketingObjectMYwZ960JErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectMYwZ960JErrorObject = {
  __typename?: 'TicketingObjectMYwZ960JErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectVyzMqZmo = {
  __typename?: 'TicketingObjectVYZMqZmo';
  error?: Maybe<TicketingObjectVyzMqZmoErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectVyzMqZmoErrorObject = {
  __typename?: 'TicketingObjectVYZMqZmoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjecta5wC2VpY = {
  __typename?: 'TicketingObjecta5wC2VpY';
  error?: Maybe<TicketingObjecta5wC2VpYErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjecta5wC2VpYErrorObject = {
  __typename?: 'TicketingObjecta5wC2VpYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectjlhvGdUs = {
  __typename?: 'TicketingObjectjlhvGDUs';
  error?: Maybe<TicketingObjectjlhvGdUsErrorObject>;
  items?: Maybe<Array<TicketingObject>>;
};

export type TicketingObjectjlhvGdUsErrorObject = {
  __typename?: 'TicketingObjectjlhvGDUsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectoAddkpUr = {
  __typename?: 'TicketingObjectoADDKPUr';
  error?: Maybe<TicketingObjectoAddkpUrErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectoAddkpUrErrorObject = {
  __typename?: 'TicketingObjectoADDKPUrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectq0pJzxG4 = {
  __typename?: 'TicketingObjectq0pJzxG4';
  error?: Maybe<TicketingObjectq0pJzxG4ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectq0pJzxG4ErrorObject = {
  __typename?: 'TicketingObjectq0pJzxG4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectzyGQj2aY = {
  __typename?: 'TicketingObjectzyGQj2aY';
  error?: Maybe<TicketingObjectzyGQj2aYErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectzyGQj2aYErrorObject = {
  __typename?: 'TicketingObjectzyGQj2aYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingOccurrenceObject = {
  __typename?: 'TicketingOccurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  closed?: Maybe<Scalars['Boolean']>;
  endUtc: Scalars['Date'];
  eventReminderEmailDelivery?: Maybe<EmailDeliveryObject>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  ticketingId: Scalars['String'];
};

export type TicketingPaymentIntentInput = {
  amount: Scalars['Float'];
  commandId?: InputMaybe<Scalars['String']>;
  connectedAccountId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
  tip: Scalars['Float'];
  ttpoi?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingRecurrenceInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  repeatUntilDate?: InputMaybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  type: TicketingRecurrenceType;
};

export type TicketingRecurrenceObject = {
  __typename?: 'TicketingRecurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  repeatUntilDate?: Maybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  ticketingId?: Maybe<Scalars['String']>;
  type: TicketingRecurrenceType;
};

export enum TicketingRecurrenceType {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  WeekDays = 'WeekDays',
  Weekly = 'Weekly'
}

export type TicketingResponseObjectErrorObject = {
  __typename?: 'TicketingResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingSummaryObject = {
  __typename?: 'TicketingSummaryObject';
  description: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
};

export enum TipModelExperience {
  Model1 = 'Model1',
  ModelA = 'ModelA',
  ModelB = 'ModelB',
  ModelC = 'ModelC',
  ModelD = 'ModelD',
  ModelE = 'ModelE'
}

export type TipNetVolumeObject = {
  __typename?: 'TipNetVolumeObject';
  volumeFee: Scalars['Float'];
  volumeTip: Scalars['Float'];
};

export type TipNetVolumeResponseObject = {
  __typename?: 'TipNetVolumeResponseObject';
  error?: Maybe<TipNetVolumeResponseObjectErrorObject>;
  object?: Maybe<TipNetVolumeObject>;
};

export type TipNetVolumeResponseObjectErrorObject = {
  __typename?: 'TipNetVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TipSuggestionObject = {
  __typename?: 'TipSuggestionObject';
  amount: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type TipSuggestionResponseListObjectErrorObject = {
  __typename?: 'TipSuggestionResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  stripePayoutId?: InputMaybe<Scalars['String']>;
};

export type TransactionObject = {
  __typename?: 'TransactionObject';
  command?: Maybe<CommandObject>;
  contact?: Maybe<ContactObject>;
  country: OrganizationCountry;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  donation?: Maybe<DonationObject>;
  donationId?: Maybe<Scalars['String']>;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning: Scalars['Boolean'];
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isImported: Scalars['Boolean'];
  isSample?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  nextPaymentAmount?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderObject>;
  orderId?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  paymentMethod: PaymentMethod;
  receipt?: Maybe<ReceiptObject>;
  receiptId?: Maybe<Scalars['String']>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  tip: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transferGroup?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Float'];
};

export type TransactionObject0Gu6eFcx = {
  __typename?: 'TransactionObject0GU6eFCX';
  error?: Maybe<TransactionObject0Gu6eFcxErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObject0Gu6eFcxErrorObject = {
  __typename?: 'TransactionObject0GU6eFCXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionObjectxqL3u02s = {
  __typename?: 'TransactionObjectxqL3u02s';
  error?: Maybe<TransactionObjectxqL3u02sErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObjectxqL3u02sErrorObject = {
  __typename?: 'TransactionObjectxqL3u02sErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionRatesWithQuantityObject = {
  __typename?: 'TransactionRatesWithQuantityObject';
  id: Scalars['String'];
  rates?: Maybe<Array<RatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectclqq78wV = {
  __typename?: 'TransactionRatesWithQuantityObjectclqq78wV';
  error?: Maybe<TransactionRatesWithQuantityObjectclqq78wVErrorObject>;
  items?: Maybe<Array<TransactionRatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectclqq78wVErrorObject = {
  __typename?: 'TransactionRatesWithQuantityObjectclqq78wVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionResponseObject = {
  __typename?: 'TransactionResponseObject';
  error?: Maybe<TransactionResponseObjectErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionResponseObjectErrorObject = {
  __typename?: 'TransactionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeListResponseObject = {
  __typename?: 'TransactionsOverTimeListResponseObject';
  error?: Maybe<TransactionsOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<TransactionsOverTimeObject>>;
};

export type TransactionsOverTimeListResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeObject = {
  __typename?: 'TransactionsOverTimeObject';
  created: Scalars['Float'];
  failed: Scalars['Float'];
  processing: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type TransactionsOverTimeResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TranslationsDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  choiceFields?: InputMaybe<Array<ChoiceFieldInput>>;
  description: Scalars['String'];
  donationFormAmountFields: Array<EditDonationFormAmountTranslationInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  message?: InputMaybe<Scalars['String']>;
  questionsFields?: InputMaybe<Array<QuestionFieldInput>>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  transferDescription?: InputMaybe<Scalars['String']>;
};

export type UnsplashImageObject = {
  __typename?: 'UnsplashImageObject';
  alt_description?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['String'];
  links: UnsplashLinks;
  urls: UnsplashUrls;
  user: UnsplashUser;
  width: Scalars['Float'];
};

export type UnsplashLinks = {
  __typename?: 'UnsplashLinks';
  download_location: Scalars['String'];
};

export enum UnsplashOrderBy {
  latest = 'latest',
  relevant = 'relevant'
}

export enum UnsplashOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  squarish = 'squarish'
}

export type UnsplashUrls = {
  __typename?: 'UnsplashUrls';
  full: Scalars['String'];
  raw: Scalars['String'];
  regular: Scalars['String'];
  small: Scalars['String'];
  thumb: Scalars['String'];
};

export type UnsplashUser = {
  __typename?: 'UnsplashUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UnsubscribePreferenceInput = {
  unsubscribedFromEmailTypes?: InputMaybe<Array<EmailType>>;
};

export type UnsubscribePreferencesObject = {
  __typename?: 'UnsubscribePreferencesObject';
  id: Scalars['String'];
  unsubscribedFromEmailTypes: Array<EmailType>;
};

export type UnsubscribePreferencesObjectkuWdqEy2 = {
  __typename?: 'UnsubscribePreferencesObjectkuWDQEy2';
  error?: Maybe<UnsubscribePreferencesObjectkuWdqEy2ErrorObject>;
  object?: Maybe<UnsubscribePreferencesObject>;
};

export type UnsubscribePreferencesObjectkuWdqEy2ErrorObject = {
  __typename?: 'UnsubscribePreferencesObjectkuWDQEy2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdateAdministrativeInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankDescriptor?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  hideFromZeffyMap?: InputMaybe<Scalars['Boolean']>;
  isPoBoxAddress: Scalars['Boolean'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propublicaCCode?: InputMaybe<Scalars['Float']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  signatureUrl?: InputMaybe<Scalars['String']>;
  type: OrganizationType;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministrativeMoreInformationInput = {
  category: OrganizationCategory;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
};

export type UpdateDonationFormAmountInput = {
  amount: Scalars['Float'];
  donationFormFieldId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type UpdateOrganizationEngagementFlagsInput = {
  engagementAddContactTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactNotesTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactPaymentsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactTagsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactsBulkTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailRecipientsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailStatsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailTemplatesTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentDetailsTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentsExportTour?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type UpdateOrganizationHubspotPropertyInput = {
  property: Scalars['String'];
  value: Scalars['String'];
};

export type UpdatePaymentIntentInput = {
  billedAmount: Scalars['Int'];
  donationId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  transferAmount: Scalars['Int'];
};

export type UpdatePaymentIntentResponse = {
  __typename?: 'UpdatePaymentIntentResponse';
  error?: Maybe<UpdatePaymentIntentResponseErrorObject>;
  object?: Maybe<UpdatePaymentIntentResponseObject>;
};

export type UpdatePaymentIntentResponseErrorObject = {
  __typename?: 'UpdatePaymentIntentResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdatePaymentIntentResponseObject = {
  __typename?: 'UpdatePaymentIntentResponseObject';
  stripePaymentIntentId: Scalars['String'];
};

export type UpdatePayoutInterval = {
  schedule: PayoutInterval;
};

export type UpdateVolumeThresholdReached = {
  volumeThresholdReached: Scalars['Float'];
};

export type UpsertSessionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  appCodeName: Scalars['String'];
  appName: Scalars['String'];
  appVersion: Scalars['String'];
  defaultSuggestedTip?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FormType>;
  id: Scalars['String'];
  isEmbed: Scalars['Boolean'];
  isSuggestedTipsAmount?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  suggestedTips?: InputMaybe<Array<Scalars['Int']>>;
  tipExperimentValue?: InputMaybe<FeatureFlagValue>;
  url: Scalars['String'];
};

export type UserDetailsObject = {
  __typename?: 'UserDetailsObject';
  firstPaymentDate?: Maybe<Scalars['Date']>;
  lastPaymentDate?: Maybe<Scalars['Date']>;
  totalContribution: Scalars['Float'];
};

export type UserListResponseObject = {
  __typename?: 'UserListResponseObject';
  error?: Maybe<UserListResponseObjectErrorObject>;
  items?: Maybe<Array<UserObject>>;
};

export type UserListResponseObjectErrorObject = {
  __typename?: 'UserListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObject = {
  __typename?: 'UserObject';
  contact?: Maybe<ContactObject>;
  contacts?: Maybe<Array<ContactObject>>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  details: UserDetailsObject;
  deviceIds?: Maybe<Array<Scalars['String']>>;
  donations?: Maybe<Array<DonationObject>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isContactOfAnotherOrganization: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<OrderObject>>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  refreshToken?: Maybe<Scalars['String']>;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  tags?: Maybe<Array<TagObject>>;
};

export type UserObject5iSfSCjz = {
  __typename?: 'UserObject5iSfSCjz';
  error?: Maybe<UserObject5iSfSCjzErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObject5iSfSCjzErrorObject = {
  __typename?: 'UserObject5iSfSCjzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectFqG1UuqT = {
  __typename?: 'UserObjectFqG1UuqT';
  error?: Maybe<UserObjectFqG1UuqTErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectFqG1UuqTErrorObject = {
  __typename?: 'UserObjectFqG1UuqTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectLyEe4aXp = {
  __typename?: 'UserObjectLYEe4aXp';
  error?: Maybe<UserObjectLyEe4aXpErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectLyEe4aXpErrorObject = {
  __typename?: 'UserObjectLYEe4aXpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectncWqS4zQ = {
  __typename?: 'UserObjectncWqS4zQ';
  error?: Maybe<UserObjectncWqS4zQErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectncWqS4zQErrorObject = {
  __typename?: 'UserObjectncWqS4zQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserPermissions {
  Bank = 'Bank',
  Contacts = 'Contacts',
  Donation = 'Donation',
  Receipt = 'Receipt',
  Ticketing = 'Ticketing',
  UserManagement = 'UserManagement'
}

export enum UserRegistrationStatus {
  Registered = 'Registered',
  Unknown = 'Unknown'
}

export type UserResponseObject = {
  __typename?: 'UserResponseObject';
  error?: Maybe<UserResponseObjectErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserResponseObjectErrorObject = {
  __typename?: 'UserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserRoles {
  Donor = 'Donor',
  Member = 'Member',
  Owner = 'Owner'
}

export type UuidInput = {
  id: Scalars['String'];
};

export type VerifyEmailObject = {
  __typename?: 'VerifyEmailObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type VerifyEmailObjectcWt0Tbs7 = {
  __typename?: 'VerifyEmailObjectcWt0TBS7';
  error?: Maybe<VerifyEmailObjectcWt0Tbs7ErrorObject>;
  object?: Maybe<VerifyEmailObject>;
};

export type VerifyEmailObjectcWt0Tbs7ErrorObject = {
  __typename?: 'VerifyEmailObjectcWt0TBS7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebAnalyticsItemObject = {
  __typename?: 'WebAnalyticsItemObject';
  connectionTime: Scalars['Float'];
  count: Scalars['Float'];
  dnsTime: Scalars['Float'];
  firstContentfulPaint: Scalars['Float'];
  firstPaint: Scalars['Float'];
  loadEventTime: Scalars['Float'];
  pageLoadTime: Scalars['Float'];
  pageRenderTime: Scalars['Float'];
  requestTime: Scalars['Float'];
  responseTime: Scalars['Float'];
  time: Scalars['String'];
};

export type WebAnalyticsObject = {
  __typename?: 'WebAnalyticsObject';
  data?: Maybe<Array<WebAnalyticsItemObject>>;
};

export type WebAnalyticsResponseObject = {
  __typename?: 'WebAnalyticsResponseObject';
  error?: Maybe<WebAnalyticsResponseObjectErrorObject>;
  object?: Maybe<WebAnalyticsObject>;
};

export type WebAnalyticsResponseObjectErrorObject = {
  __typename?: 'WebAnalyticsResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeListResponseObject = {
  __typename?: 'WebhooksOverTimeListResponseObject';
  error?: Maybe<WebhooksOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<WebhooksOverTimeObject>>;
};

export type WebhooksOverTimeListResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeObject = {
  __typename?: 'WebhooksOverTimeObject';
  failed: Scalars['Float'];
  queued: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type WebhooksOverTimeResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ZeffyImportObject = {
  __typename?: 'ZeffyImportObject';
  id: Scalars['String'];
};
